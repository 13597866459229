import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Transition from "../../Helper/Transitionroute";
import { Input } from "antd";
import Select from "react-select";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import Apipost from "../../Helper/Apipost";
import Standardoptions from "../../Options/Standardoptions";
import Apifetch from "../../Helper/Apifetch";
import { AuthdetailsContext } from "../../Provider/Authdetailsprovider";

export default function Staff_creation() {
  const { gid } = React.useContext(AuthdetailsContext);

  const [sub, setsub] = useState([
    {
      subject_name: "",
      description: "",
    },
  ]);
  const [open1, setopen1] = useState(false);
  const [res, setres] = useState([]);
  const [name, setname] = useState("");
  const [role, setrole] = useState("Teaching");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [password, setpassword] = useState("");
  const [status, setstatus] = useState("");
  const [staffid, setstaffid] = useState("");
  const [designation, setdesignation] = useState("");
  async function postData(event) {
    event.preventDefault();

    try {
      const formdata = {
        Name: name,
        Role: role,
        Contact_number: contact,
        Email: email,
        Password: password,
        Status: status,
        Staffid: staffid,
        Designation: designation,
        monday: ["-", "-", "-", "-", "-", "-", "-", "-"],
        tuesday: ["-", "-", "-", "-", "-", "-", "-", "-"],
        wednesday: ["-", "-", "-", "-", "-", "-", "-", "-"],
        thursday: ["-", "-", "-", "-", "-", "-", "-", "-"],
        friday: ["-", "-", "-", "-", "-", "-", "-", "-"],
        erpidunique: gid,
      };
      const formdata2 = {
        username: staffid,
        email: email,
        password: "rooster",
      };
      Apipost("staffdetails/", formdata);
      Apipost("register/", formdata2);
      setname("");
      setcontact("");
      setdesignation("");
      setemail("");
      Apifetch(`staffdetails/`, setres).then((res) => {
        console.log(res.data.length);
        const str1 = (res.data.length + 1).toString();
        console.log(str1.padStart(3, "0"));
        setstaffid("T" + str1.padStart(3, "0"));
      });
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  }
  useEffect(() => {
    Apifetch(`staffdetails/?Role=Teaching&Status=&Staffid=`, setres).then(
      (res) => {
        console.log(res.data.length);
        const str1 = (res.data.length + 1).toString();
        console.log(str1.padStart(3, "0"));
        setstaffid(gid + ".T" + str1.padStart(3, "0"));
      }
    );
  }, []);
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">STAFF</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Name</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={name}
                  className="textipbill"
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
              </div>
              <div class="grid-item">Designation</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={designation}
                  className="textipbill"
                  onChange={(e) => {
                    setdesignation(e.target.value);
                  }}
                />
              </div>

              <div class="grid-item">Contact</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={contact}
                  className="textipbill"
                  onChange={(e) => {
                    setcontact(e.target.value);
                  }}
                />
              </div>
              <div class="grid-item">Email</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={email}
                  className="textipbill"
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                />
              </div>
              <div class="grid-item">Staff Id</div>
              <div class="grid-item">
                <Input
                  disabled
                  type="text"
                  value={staffid}
                  className="textipbill"
                  onChange={(e) => {
                    setstaffid(e.target.value);
                  }}
                />
              </div>
              <div class="grid-item">Role</div>
              <div class="grid-item">
                <Input
                  disabled
                  type="text"
                  value={role}
                  className="textipbill"
                  onChange={(e) => {
                    setrole(e.target.value);
                  }}
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={postData} size="sm">
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Maincomponent>
  );
}
