import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../../Components/Maincomponent/Maincomponent";

import axios from "axios";
import Apiurl from "../../../Apiurl";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { Tag } from "antd";

export default function Substitutionall() {
  const apiurl = Apiurl();
  const [jsonList, setJsonList] = useState([]);
  const [data, setdata] = useState([]);
  const { user } = useParams();

  const [showtable, setshowtable] = useState(false);

  const fetchdata = async () => {
    try {
      // console.log(apiurl + "timetablesection");
      const response = await axios.get(apiurl + "subustitution/");
      console.log(response.data);
      setdata(response.data);
      setshowtable(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);
      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>
              <td>
                {item.s_staffid} - {item.s_subject}
              </td>
              <td>{item.period}</td>
              <td>{item.date}</td>
              <td>{item.index}</td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      return (
        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Substituted Staff</th>

              <th> Standard - Section</th>

              <th> Date</th>

              <th> Period</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">SUBSTITUTION ALL</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              {showtable && (
                <Button
                  onClick={() => {
                    window.open(
                      `/timetable_report_pdf/${user}/substitution/@#$%^`
                    );
                  }}
                  variant="outline-danger"
                >
                  Generate Pdf
                </Button>
              )}
            </div>
          </div>
        </div>
        <br />
        <Tablecomp data={data}  className="showtablestyle" />
      </div>
    </Maincomponent>
  );
}
