import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Transition from "../../Helper/Transitionroute";
import { Input } from "antd";
import Select from "react-select";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import Apipost from "../../Helper/Apipost";
import Standardoptions from "../../Options/Standardoptions";
import Apifetch from "../../Helper/Apifetch";
import Apiput from "../../Helper/Apiput";

export default function Subject_update() {
  const { id } = useParams();
  const navigate = useNavigate();
  const standard_options = Standardoptions();
  const [data, setData] = useState([]);
  const [standard, setstandard] = useState(null);
  const [res, setres] = useState([]);
  const [groupoptions, setgroupoptions] = useState([]);
  const [description, setdescription] = useState("");
  const [group, setgroup] = useState(null);
  const [sub, setsub] = useState([
    {
      subject_name: "",
      description: "",
    },
  ]);
  const [open1, setopen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });
  const handleFormChange = (index, event) => {
    let data = [...sub];
    data[index][event.target.name] = event.target.value;
    setsub(data);
  };
  const removeFields = (index) => {
    let data = [...sub];
    data.splice(index, 1);
    setsub(data);
  };
  const addFields = () => {
    let newfield = {
      subject_name: "",
      hours: 0,
      description: "",
    };

    setsub([...sub, newfield]);
  };
  async function postData(event) {
    event.preventDefault();

    try {
      const formdata = {
        standard: standard.value,
        description: description,
        subject: sub,
        group: group == null ? "" : group.value,
      };
      Apiput(`subject/${id}/`, formdata);
      navigate(`/view_subject`);
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  }
  async function fetchsectionData(val) {
    try {
      // const response = await axios.get(apiurl + `course/?course_name=${val}`);
      Apifetch(`course/?course_name=${val}`, setres).then((res) => {
        console.log(res.data);
        const convertedarray = res.data[0].group.map((item, index) => ({
          value: item.group,
          label: item.group,
        }));

        setgroupoptions(convertedarray);
      });
    } catch (error) {
      alert(error.message);
    }
  }
  useEffect(() => {
    Apifetch(`subject/${id}/`, setData).then((res) => {
      console.log(res.data);
      setstandard({
        label: res.data.standard,
        value: res.data.standard,
      });
      setdescription(res.data.description);
      setgroup({
        label: res.data.group,
        value: res.data.group,
      });
      setsub(res.data.subject);
      if ((res.data.standard == "XII") | (res.data.standard == "XI")) {
        fetchsectionData(res.data.standard);
      }
    });
  }, []);
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">SUBJECT</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div className="grid-item">Standard</div>
              <div className="grid-item">
                <Select
                  value={standard}
                  isDisabled
                  options={standard_options}
                  onChange={(value) => {
                    setstandard(value);
                    setgroup(null);
                    if ((value.value == "XII") | (value.value == "XI")) {
                      fetchsectionData(value.value);
                      console.log(value);
                    }
                  }}
                  placeholder="Select Standard"
                />
              </div>
              {standard &&
              (standard.value == "XII") | (standard.value == "XI") ? (
                <>
                  <div className="grid-item">Group</div>
                  <div className="grid-item">
                    <Select
                      value={group}
                      isDisabled
                      options={groupoptions}
                      onChange={(value) => {
                        setgroup(value);
                      }}
                      placeholder="Select Group"
                    />
                  </div>
                </>
              ) : null}
              <div className="grid-item">Section</div>
              <div className="grid-item">
                <Input
                  type="text"
                  disabled
                  value={sub.length}
                  className="textipbill"
                  style={{ width: "85%" }}
                />
                &nbsp;
                <Button
                  size="sm"
                  variant="warning"
                  onClick={() => {
                    setopen1(true);
                  }}
                >
                  +
                </Button>
              </div>
            </div>
            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={postData} size="sm">
                Update
              </Button>
            </div>
          </div>
        </div>
        <Modal
          // title="Group"
          // centered
          open={open1}
          styles={{
            content: { backgroundColor: "white" }, // turns the Modal red
          }}
          onOk={() => setopen1(false)}
          onCancel={() => setopen1(false)}
          width={1200}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <Button size="sm" onClick={addFields}>
                Add
              </Button>
              &nbsp;
              {/* <CancelBtn /> */}
              <OkBtn size="sm" />
            </>
          )}
        >
          <h5>Subject</h5>
          Hour{" "}
          {sub.reduce(
            (total, item) => parseInt(total) + parseInt(item.hours),
            0
          )}{" "}
          /40
          <hr></hr>
          <table>
            <tr style={{ padding: 2 }}>
              {/* <th> Sno</th> */}
              <th>Subject</th>
              <th>Hour</th>
              <th>Description</th>
              <th></th>
            </tr>

            {sub.map((input, index) => {
              return (
                <>
                  <tr>
                    {/* <th>{index + 1}</th> */}
                    <th>
                      {" "}
                      <Input
                        name="subject_name"
                        className="textipbill"
                        style={{
                          width: "150px",
                          border: "0.5px solid gray",
                          color: "black",
                        }}
                        placeholder="Subject"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.subject_name}
                      />
                    </th>
                    <th>
                      {" "}
                      <Input
                        type="number"
                        name="hours"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="hour"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.hours}
                      />{" "}
                    </th>
                    <th>
                      {" "}
                      <Input
                        name="description"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="description"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.description}
                      />{" "}
                    </th>
                    <td>
                      {" "}
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => removeFields(index)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
          <br />
        </Modal>
      </div>
    </Maincomponent>
  );
}
