import React from 'react';
import { useAuth } from './Auth';
import { Navigate } from 'react-router-dom';

export default function RequireAuth({children}) {
    const auth = useAuth();
    if(!auth.user){
        alert("Login To Access...")
        return <Navigate to={"/"}/>
       
    }
  return children;
}
