import axios from "axios";
import Apiurl from "../Apiurl";
export default async function Apifetch(url, setdata) {
  const apiurl = Apiurl();
  try {
    const response = await axios.get(apiurl + url);
    setdata(response.data);
    return response;
  } catch (error) {
    console.error(error);
    return [{ error: "Error Fetching data !" }];
  }
}
