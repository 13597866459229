import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    borderWidth: 1,
    fontSize: 5,
    fontFamily: "Times-Roman",
    padding: 20,
  },
  viewer: {
    width: "100%", // Use percentage to ensure responsiveness
    height: "100vh", // Use viewport height for full height
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    // marginBottom: 10,
    fontSize: 5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "#f0f0f0",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 9,
  },
});

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};
function Substitution_monthly_pdf() {
  const apiurl = Apiurl();
  const { id, data } = useParams();
  const [stddata, setstddata] = useState([]);

  const fetchdata = async () => {
    try {
      // console.log(apiurl + "timetablesection");
      console.log(data);
      let decodedValue = atob(data);
      const ddata = JSON.parse(decodedValue);
      console.log(ddata);
      const response = await axios.get(
        apiurl +
          `subustitution_monthly_api/?year=${ddata.year}&month=${ddata.month}`
      );
      console.log(response.data);
      setstddata(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  // Split data into chunks of 3 items each
  const chunks = chunkArray(stddata, 10);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {chunks.map((chunk, pageIndex) => (
          <Page key={pageIndex} size="A4" style={styles.page}>
            <View style={{ marginBottom: 0 }}>
              <Text
                style={{ marginBottom: 0, fontSize: 14, fontWeight: "bold" }}
              ></Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Sno</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Date</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      Substituted Staff
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>
                      Standard - Section
                    </Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCellHeader}>Period </Text>
                  </View>
                </View>
              </View>
            </View>
            {chunk.map((item, index) => (
              <View key={index} style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{index + 1}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.date}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {" "}
                    {item.s_staffid} - {item.s_subject}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.period}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.index}</Text>
                </View>
              </View>
            ))}
          </Page>
        ))}
      </Document>
    </PDFViewer>
  );
}

export default Substitution_monthly_pdf;
