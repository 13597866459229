import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../../Components/Maincomponent/Maincomponent";
import axios from "axios";
import Apiurl from "../../../Apiurl";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { Input, Tag } from "antd";
import Select from "react-select";
import Standardoptions from "../../../Options/Standardoptions";

export default function Class_datespecific() {
  const { user } = useParams();
  const apiurl = Apiurl();

  const [data, setdata] = useState([]);
  const Courseoption = Standardoptions();
  const [showtable, setshowtable] = useState(false);

  const [date, setdate] = useState("");

  const [standard, setstandard] = useState(null);
  const [sectionoption, setsectionoption] = useState([]);
  const [section, setsection] = useState(null);

  const fetchdata = async () => {
    try {
      // console.log(apiurl + "timetablesection");
      const response = await axios.get(
        apiurl +
          `classwise_datespecific/?standard=${standard.value}&section=${section.value}&date=${date}`
      );
      console.log(response.data);
      setdata(response.data);
      setshowtable(true);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      alert(error.response.data.error);
    }
  };

  async function getsectionoption(standard) {
    try {
      const response = await axios.get(
        apiurl + `section/?standard_name=${standard.value}`
      );

      const convertedoptions = response.data[0].section.map(
        (student, index) => ({
          value: student.section,
          label: student.section,
        })
      );
      setsectionoption(convertedoptions);
    } catch (error) {
      console.error(error);
      // alert("error");
    }
  }
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">CLASS DATE SPECIFIC</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">
                {" "}
                <Select
                  style={{ width: "100%" }}
                  value={standard === "" ? "Select Standard" : standard}
                  onChange={(selectedOption) => {
                    setstandard(selectedOption);
                    getsectionoption(selectedOption);
                    setshowtable(false);
                    setsection("");
                  }}
                  options={Courseoption}
                  placeholder="Select Standard"
                />
              </div>
              <div class="grid-item">
                {" "}
                <Select
                  style={{ width: "100%" }}
                  value={section === "" ? "Select Section" : section}
                  onChange={(selectedOption) => {
                    setsection(selectedOption);
                    setshowtable(false);
                  }}
                  options={sectionoption}
                  placeholder="Select Section"
                />
              </div>
              <div class="grid-item">
                {" "}
                <Input
                  type="date"
                  size="large"
                  value={date}
                  onChange={(e) => {
                    setdate(e.target.value);
                    setshowtable(false);
                  }}
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={fetchdata} size="sm" variant="primary">
                Find
              </Button>
              &nbsp;&nbsp;
              {showtable && (
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    const data = {
                      standard: standard.value,
                      section: section.value,
                      date: date,
                    };
                    const aJSONString = JSON.stringify(data);
                    let encodedValue = btoa(aJSONString);
                    window.open(
                      `/timetable_report_pdf/${user}/class_date_specific/${encodedValue}`
                    );
                  }}
                >
                  Generate Pdf
                </Button>
              )}
            </div>
          </div>
        </div>
        <br />
        {showtable && (
          <>
            {data.length == 0 ? (
              <center>
                <h5>NO RECORDS FOUND !</h5>
              </center>
            ) : (
              <div className="showtablestyle" style={{ padding: "5px" }}>
                {data.map((item, index) => (
                  <>
                    Timetable Created on {item.date}
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <Tag color="blue">
                              {item.standard} - {item.section}
                            </Tag>
                          </th>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Monday</th>
                          {item.monday.map((subject, index) => (
                            <td key={index}>{subject}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Tuesday</th>
                          {item.tuesday.map((subject, index) => (
                            <td key={index}>{subject}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Wednesday</th>
                          {item.wednesday.map((subject, index) => (
                            <td key={index}>{subject}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Thursday</th>
                          {item.thursday.map((subject, index) => (
                            <td key={index}>{subject}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Friday</th>
                          {item.friday.map((subject, index) => (
                            <td key={index}>{subject}</td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </Maincomponent>
  );
}
