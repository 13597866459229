import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../../Components/Maincomponent/Maincomponent";
import axios from "axios";
import Apiurl from "../../../Apiurl";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { Input, Tag } from "antd";
import Select from "react-select";
import { DatePicker, Space } from "antd";
import Standardoptions from "../../../Options/Standardoptions";

export default function Substitutionmonthly() {
  const apiurl = Apiurl();
  const { user } = useParams();
  const [jsonList, setJsonList] = useState([]);
  const [data, setdata] = useState([]);
  const Courseoption = Standardoptions();
  const [showtable, setshowtable] = useState(false);

  const [date, setdate] = useState("");

  const fetchdata = async () => {
    try {
      const splitval = date.split("-");
      const response = await axios.get(
        apiurl +
          `subustitution_monthly_api/?year=${splitval[0]}&month=${splitval[1]}`
      );
      console.log(response.data);
      setdata(response.data);
      setshowtable(true);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      alert(error.response.data.error);
      setdata([]);
    }
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setdate(dateString);
    setshowtable(false);
  };
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);
      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>
              <td>
                {item.s_staffid} - {item.s_subject}
              </td>
              <td>{item.period}</td>
              <td>{item.date}</td>
              <td>{item.index}</td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      return (
        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Substituted Staff</th>

              <th> Standard - Section</th>

              <th> Date</th>

              <th> Period</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">SUBSTITUTION MONTHLY</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Date</div>
              <div class="grid-item">
                {" "}
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={onChange}
                  picker="month"
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={fetchdata} size="sm" variant="primary">
                Find
              </Button>
              &nbsp;&nbsp;
              {showtable && (
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    const splitval = date.split("-");
                    const data = {
                      // staffid: staff.value,
                      year: splitval[0],
                      month: splitval[1],
                    };
                    const aJSONString = JSON.stringify(data);
                    let encodedValue = btoa(aJSONString);
                    window.open(
                      `/timetable_report_pdf/${user}/substitution_monthly/${encodedValue}`
                    );
                  }}
                >
                  Generate Pdf
                </Button>
              )}
            </div>
          </div>
        </div>
        <br />
        {showtable && (
          <>
            {data.length == 0 ? (
              <center>
                <h5>NO RECORDS FOUND !</h5>
              </center>
            ) : (
              <Tablecomp data={data} />
            )}
          </>
        )}
      </div>
    </Maincomponent>
  );
}
