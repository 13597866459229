import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Select } from "antd";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

import Table from "react-bootstrap/Table";
import { TimetableContext } from "../../Provider/Timetableprovider";
import { useContext } from "react";
import Dynamicselect from "./Dynamicselect";
import Standardoptions from "../../Options/Standardoptions";
import Apiurl from "../../Apiurl";
import axios from "axios";
import { AuthdetailsContext } from "../../Provider/Authdetailsprovider";

export default function Timetable_generate() {
  const { gid } = React.useContext(AuthdetailsContext);

  const Courseoption = Standardoptions();
  const [open1, setopen1] = useState(false);
  const [res, setres] = useState([]);
  const [name, setname] = useState("");
  const navigate = useNavigate();
  const [designation, setdesignation] = useState("");
  const apiurl = Apiurl();
  const [isupdate, setisupdate] = useState(true);
  const [gentimetable, setgentimetable] = useState([]);

  const [standard, setstandard] = useState("");
  const [sectionoption, setsectionoption] = useState([]);
  const [section, setsection] = useState("");

  const [showtable, setshowtable] = useState(false);

  const data = [
    { day: "Monday", periods: "M" },
    { day: "Tuesday", periods: "T" },
    { day: "Wednesday", periods: "W" },
    { day: "Thursday", periods: "TH" },
    { day: "Friday", periods: "F" },
    // { day: "Saturday", periods: "S" },
  ];
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api.open({
      message: "Alert",
      description: "Regenerated Time Table.!",
      placement,

      icon: (
        <InfoCircleOutlined
          style={{
            color: "green",
          }}
        />
      ),
    });
  };

  const [testopt, settestopt] = useState([]);

  const { timetable, settimetable, teacheropt, setteacheropt } =
    useContext(TimetableContext);

  async function getsectionoption(standard) {
    try {
      const response = await axios.get(
        apiurl + `section/?standard_name=${standard}`
      );
      const convertedoptions = response.data[0].section.map(
        (student, index) => ({
          value: student.section,
          label: student.section,
        })
      );
      setsectionoption(convertedoptions);
    } catch (error) {
      console.error(error);
      alert("error");
    }
  }
  async function getteacheropt() {
    try {
      const response = await axios.get(
        apiurl +
          `staff_subject_with_timetable/?staff_id=&section=${section}&standard=${standard}`
      );
      const responseb = await axios.get(
        apiurl + `timetablesection/?standard=${standard}&section=${section}`
      );
      const responsec = await axios.get(
        apiurl + `subject/?standard=${standard}`
      );
      // console.log(responsebc.data[0].subject);
      if (responseb.data.length > 0) {
        setisupdate(true);
        setgentimetable(responseb.data);
        openNotification("bottomRight");
        setteacheropt(response.data);
        settestopt(response.data);

        gen_timetable(responsec.data[0].subject, response.data);
        // gen_timetable(responsec.data[0].subject, response.data);

        setshowtable(true);
      } else {
        if (response.data.length == 0) {
          alert("Allocate Teachers To Generate Timetable !");
        } else {
          setisupdate(false);
          setteacheropt(response.data);
          settestopt(response.data);
          console.log(testopt);

          gen_timetable(responsec.data[0].subject, response.data);

          setshowtable(true);
        }
      }
    } catch (error) {
      console.error(error);
      alert("error");
    }
  }

  async function useeftfunction() {
    try {
      const response = await axios.get(
        apiurl +
          `staff_subject_with_timetable/?staff_id=&section=${section}&standard=${standard}`
      );
      const responseb = await axios.get(
        apiurl + `timetablesection/?standard=${standard}&section=${section}`
      );
      const responsec = await axios.get(
        apiurl + `subject/?standard=${standard}`
      );
      // console.log(responsebc.data[0].subject);
      if (responseb.data.length > 0) {
        // alert("Timetable Already Generated !");
        setisupdate(true);
        setgentimetable(responseb.data);
        // openNotification("bottomRight");
        setteacheropt(response.data);
        settestopt(response.data);
        setisupdate(true);
      } else {
        if (response.data.length == 0) {
          alert("Allocate Teachers To Generate Timetable !");
        } else {
          setteacheropt(response.data);
          settestopt(response.data);
          console.log(testopt);
          setisupdate(false);
          gen_timetable(responsec.data[0].subject, response.data);
        }
      }
    } catch (error) {
      console.error(error);
      alert("error");
    }
  }

  const daydata = ["monday", "tuesday", "wednesday", "thursday", "friday"];

  function gen_timetable(subjects, teacherdata) {
    let maxAttempts = 100; // Maximum attempts to avoid infinite loop
    let attemptCount = 0;
    let success = false;

    while (!success && attemptCount < maxAttempts) {
      attemptCount++;
      let subjectIndex = attemptCount % subjects.length; // Start with a different subject each attempt
      const subjectHoursAllocated = {};
      for (let subject of subjects) {
        subjectHoursAllocated[subject.subject_name] = 0;
      }

      success = true; // Assume success until proven otherwise

      // Clear the timetable
      for (let day of daydata) {
        const capitalized = day.charAt(0).toUpperCase() + day.slice(1);
        for (let i = 0; i < timetable[capitalized].length; i++) {
          timetable[capitalized][i] = "-";
        }
      }

      for (let day of daydata) {
        const capitalized = day.charAt(0).toUpperCase() + day.slice(1);
        for (let i = 0; i < timetable[capitalized].length; i++) {
          let allocated = false;
          let attempts = 0;
          while (!allocated && attempts < subjects.length) {
            const currentSubject = subjects[subjectIndex];
            const currentSubjectName = currentSubject.subject_name;

            if (
              subjectHoursAllocated[currentSubjectName] <
              parseInt(currentSubject.hours)
            ) {
              const subject_teacher = teacherdata.filter(
                (teacher) => teacher.subject == currentSubjectName
              );
              const val = subject_teacher[0].Staffid + "-" + currentSubjectName;
              if (subject_teacher[0][day][i] == "-") {
                handleTimetableChange(capitalized, i, val);

                allocated = true;
                subjectHoursAllocated[currentSubjectName]++;
              }
            }
            subjectIndex = (subjectIndex + 1) % subjects.length;
            attempts++;
          }
          if (!allocated) {
            console.log(
              `No subject allocated for ${capitalized}, period ${i} on attempt ${attemptCount}`
            );
            success = false; // Mark as unsuccessful allocation
            break; // Break out of the period loop
          }
        }
        if (!success) break; // Break out of the day loop if allocation fails
      }
    }

    if (!success) {
      console.log(
        "Failed to generate a valid timetable after maximum attempts."
      );
      alert("Please Allocate Some More Teachers !");
    } else {
      console.log("Timetable successfully generated.");
    }
  }

  const handleTimetableChange = (day, periodIndex, value) => {
    settimetable((prevTimetable) => {
      const updatedDay = [...prevTimetable[day]];
      updatedDay[periodIndex] = value;
      return { ...prevTimetable, [day]: updatedDay };
    });
    const val1 = value.split("-");
    console.log(val1);
    const lowercaseDay = day.toLowerCase();
    console.log(lowercaseDay);
    const updatedScheduleData = teacheropt.map((staff) => {
      if (staff.Staffid === val1[0]) {
        console.log(staff[lowercaseDay][periodIndex]);
        staff[lowercaseDay][periodIndex] = standard + "-" + section;
        console.log(staff[lowercaseDay][periodIndex]);
      }
      return staff;
    });

    settestopt(updatedScheduleData);
    // setteacheropt(updatedScheduleData);
    console.warn(updatedScheduleData);
  };

  useEffect(() => {
    useeftfunction();
  }, [section]);
  const submit = async () => {
    try {
      const response = await axios.put(apiurl + `bullkstaffupdate/`, testopt);
      const fmdata = {
        standard: standard,
        section: section,
        monday: timetable.Monday,
        tuesday: timetable.Tuesday,
        wednesday: timetable.Wednesday,
        thursday: timetable.Thursday,
        friday: timetable.Friday,
        saturday: timetable.Saturday,
      };

      const datahistory = {
        standard: standard,
        section: section,
        monday: timetable.Monday,
        tuesday: timetable.Tuesday,
        wednesday: timetable.Wednesday,
        thursday: timetable.Thursday,
        friday: timetable.Friday,
        saturday: timetable.Saturday,
        generated_by: "Admin",
        erpidunique: gid,
      };
      if (!isupdate) {
        const response2 = await axios.post(
          apiurl + `timetablesection/`,
          fmdata
        );
        console.log(response2.data);
      } else {
        const gentimetabledata = {
          standard: gentimetable[0].standard,
          section: gentimetable[0].section,
          monday: timetable.Monday,
          tuesday: timetable.Tuesday,
          wednesday: timetable.Wednesday,
          thursday: timetable.Thursday,
          friday: timetable.Friday,
          saturday: timetable.Saturday,
          erpidunique: gid,
        };
        const responseup = await axios.put(
          apiurl + `timetablesection/${gentimetable[0].id}/`,
          gentimetabledata
        );
        console.log("update");
      }
      const response3 = await axios.post(
        apiurl + `timetablehistory/`,
        datahistory
      );
      navigate(`/view_timetable`);
      alert("Time Table Created");
    } catch (error) {
      console.error(error);
      alert("error");
    }
  };

  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">TIMETABLE</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Standard</div>
              <div class="grid-item">
                <Select
                  value={standard === "" ? "Select Standard" : standard}
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setstandard(selectedOption);
                    getsectionoption(selectedOption);
                    setshowtable(false);
                    setsection("");
                  }}
                  options={Courseoption}
                  placeholder="Select Standard"
                />
              </div>
              <div class="grid-item">Designation</div>
              <div class="grid-item">
                <Select
                  value={section === "" ? "Select Section" : section}
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setsection(selectedOption);
                    setshowtable(false);
                  }}
                  options={sectionoption}
                  placeholder="Select Section"
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={getteacheropt} size="sm">
                Submit
              </Button>
            </div>
          </div>
        </div>
        {showtable && (
          <div className="tablecomp" style={{ padding: "10px" }}>
            <Table className="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  {Array.from({ length: 8 }, (_, i) => (
                    <th key={i}>{i + 1}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index + 1}>
                    <th>{item.day}</th>
                    {Array.from({ length: 8 }, (_, periodIndex) => (
                      <>
                        <td key={periodIndex}>
                          <Dynamicselect
                            timetabledata={timetable}
                            standard={standard}
                            section={section}
                            props={periodIndex}
                            day={item.day}
                            changefunction={(value) =>
                              handleTimetableChange(
                                item.day,
                                periodIndex,
                                value
                              )
                            }
                          />
                        </td>
                      </>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {showtable && (
          <Button style={{ width: "100px" }} size="sm" onClick={submit}>
            Submit
          </Button>
        )}
      </div>
    </Maincomponent>
  );
}
