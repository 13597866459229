import axios from "axios";
import Apiurl from "../Apiurl";
export default async function Apiput(url, formdata) {
  const apiurl = Apiurl();
  try {
    const response = await axios.put(apiurl + url, formdata);
    console.log(response.data);
    alert("Updated Successfully");
    return response.data;
  } catch (error) {
    console.error(error);
    return [{ error: "Put Failed !" }];
  }
}
