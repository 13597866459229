import React, { useState, useEffect, useContext } from "react";
import { Select } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";

import { TimetableContext } from "../../Provider/Timetableprovider";

export default function Dynamicselect({
  props,
  changefunction,
  dataoptions,
  section,
  standard,
  day,
  timetabledata,
}) {
  const apiurl = Apiurl();
  const [opt, setopt] = useState([]);

  const { timetable, settimetable, teacheropt, setteacheropt } =
    useContext(TimetableContext);

  function getRandomNumber() {
    return Math.floor(Math.random() * opt.length);
  }
  const [defaultValue, setDefaultValue] = useState(null);

  const handleTimetableChange = (day, periodIndex, value) => {
    settimetable((prevTimetable) => {
      const updatedDay = [...prevTimetable[day]];
      updatedDay[periodIndex] = value;
      return { ...prevTimetable, [day]: updatedDay };
    });
    const val1 = value.split("-");
    // console.log(val1);
    const lowercaseDay = day.toLowerCase();
    const updatedScheduleData = teacheropt.map((staff) => {
      if (staff.Staffid === val1[0]) {
        staff[lowercaseDay][periodIndex] = standard + "-" + section;
        // console.log(staff.staff_id);
      }
      return staff;
    });
    setteacheropt(updatedScheduleData);
    // console.warn(teacheropt);
  };

  async function fetchData() {
    try {
      const response = await axios.get(
        apiurl +
          `staff_subject_with_timetable/?staff_id=&section=${section}&standard=${standard}`
      );
      // console.log(response.data);
      const lowercaseDay = day.toLowerCase();
      const filteredData = response.data.filter(
        (student) =>
          student[lowercaseDay] && student[lowercaseDay][props] === "-"
      );
      const options3 = filteredData.map((student) => ({
        value: student.Staffid + "-" + student.subject,
        label: student.Staffid + "-" + student.subject,
      }));
      setopt(options3);
      if (options3.length > 0) {
        const randomIndex = Math.floor(Math.random() * options3.length);
        const defaultValue = options3[randomIndex].value;
        // setDefaultValue(defaultValue);
        // handleTimetableChange(day, props, defaultValue);
      }
    } catch (error) {
      console.error(error);
      // Handle error here
      console.log("error");
    }
  }
  const a = getRandomNumber();
  const dftval = opt && opt[a]?.value;

  useEffect(() => {
    fetchData();
  }, [0]);
  return (
    <div>
      <Select
        style={{ width: "80%" }}
        onChange={changefunction}
        options={opt}
        // allowClear
        // disabled
        placeholder={dftval}
        value={timetable[day][props]}
      />
      {/* {timetabledata[day][props == 0 ? 0 : props - 1]} */}
      {/* {day} */}
    </div>
  );
}

// //
// import React, { useState, useEffect, useCallback, useMemo } from "react";
// import { Select } from "antd";
// import axios from "axios";
// import Apiurl from "../../../Apiurl";

// export default function Dynamicselect({
//   props,
//   changefunction,
//   section,
//   standard,
//   day,
// }) {
//   const apiurl = Apiurl();
//   const [opt, setOpt] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await axios.get(
//         `${apiurl}staff_subject_with_timetable/?staff_id=&section=${section}&standard=${standard}`
//       );
//       const lowercaseDay = day.toLowerCase();
//       const filteredData = response.data.filter(
//         (student) =>
//           student[lowercaseDay] && student[lowercaseDay][props] === "-"
//       );
//       const options3 = filteredData.map((student) => ({
//         value: student.Staffid + "-" + student.subject,
//         label: student.Staffid + "-" + student.subject,
//       }));
//       setOpt(options3);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setLoading(false);
//     }
//   }, [apiurl, section, standard, day, props]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   const getRandomNumber = useCallback(() => {
//     return Math.floor(Math.random() * opt.length);
//   }, [opt.length]);

//   const dftval = useMemo(() => {
//     const randomIndex = getRandomNumber();
//     return opt[randomIndex]?.value;
//   }, [opt, getRandomNumber]);

//   const memoizedChangeFunction = useCallback((value) => {
//     changefunction(value);
//   }, [changefunction]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <Select
//         style={{ width: "80%" }}
//         onChange={memoizedChangeFunction}
//         options={opt}
//         allowClear
//         placeholder={dftval}
//       />
//     </div>
//   );
// }
