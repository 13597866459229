import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.css";

import { AuthProvider } from "./Auth";
import RequireAuth from "./RequireAuth";
import { useAuth } from "./Auth";

import Update_standard from "./Pages/Standard/Update_standard";
import Section_creation from "./Pages/Section/Section_creation";
import Section_view from "./Pages/Section/Section_view";
import Section_update from "./Pages/Section/Section_update";
import Subject_creation from "./Pages/Subject/Subject_creation";
import Subject_view from "./Pages/Subject/Subject_view";
import Subject_update from "./Pages/Subject/Subject_update";
import Staff_creation from "./Pages/Staff/Staff_creation";
import Staff_view from "./Pages/Staff/Staff_view";
import Staff_update from "./Pages/Staff/Staff_update";
import Subjectallocation from "./Pages/Staff/Subjectallocation";
import Timetable_generate from "./Pages/Timetable/Timetable_generate";
import { TimetableProvider } from "./Provider/Timetableprovider";
import View_timetable from "./Pages/Timetable/Viewt_timetable";
import Staff_leave from "./Pages/Staff/Staff_leave";
import Substitution from "./Pages/Timetable/Substitution";
import Timetablereportpdf from "./Pages/Timetable/Timetablereportpdf";
import Allclass from "./Pages/Timetable/TimetableReports/Allclass";
import Allstaff from "./Pages/Timetable/TimetableReports/Allstaff";
import Class_datespecific from "./Pages/Timetable/TimetableReports/Class_datespecific";
import Classwise from "./Pages/Timetable/TimetableReports/Classwise";
import Staff_datespecific from "./Pages/Timetable/TimetableReports/Staff_datespecific";
import Substitutionall from "./Pages/Timetable/TimetableReports/Substitutionall";
import Substitutionmonthly from "./Pages/Timetable/TimetableReports/Substitutionmonthly";
import Staff_classwise from "./Pages/Timetable/TimetableReports/Staff_classwise";
import { Authdetailsprovider } from "./Provider/Authdetailsprovider";

const Loginpage = React.lazy(() => import("./Pages/Loginpage/Login"));
const Licencepage = React.lazy(() => import("./Pages/Licencepage/Licencepage"));

const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));
const Loader = React.lazy(() => import("./Components/Loader/Loader"));
const Create_standard = React.lazy(() =>
  import("./Pages/Standard/Create_standard")
);
const View_standard = React.lazy(() =>
  import("./Pages/Standard/View_standard")
);

function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<Loader />}>
        <Authdetailsprovider>
          <BrowserRouter>
            <AnimatePresence mode="wait">
              <Routes>
                <Route path="/" element={<Loginpage />} exact />
                <Route path="/licence" element={<Licencepage />} exact />
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                  exact
                />
                {/* Standard */}
                <Route
                  path="/create_standard"
                  element={<Create_standard />}
                  exact
                />
                <Route
                  path="/update_Standard/:id"
                  element={<Update_standard />}
                  exact
                />
                <Route
                  path="/view_standard"
                  element={<View_standard />}
                  exact
                />
                {/* Section */}
                <Route path="/view_section" element={<Section_view />} exact />
                <Route
                  path="/create_section"
                  element={<Section_creation />}
                  exact
                />
                <Route
                  path="/update_section/:id"
                  element={<Section_update />}
                  exact
                />

                {/* Subject */}
                <Route path="/view_subject" element={<Subject_view />} exact />
                <Route
                  path="/create_subject"
                  element={<Subject_creation />}
                  exact
                />
                <Route
                  path="/update_subject/:id"
                  element={<Subject_update />}
                  exact
                />

                {/* Staff */}
                <Route path="/view_staff" element={<Staff_view />} exact />
                <Route
                  path="/create_staff"
                  element={<Staff_creation />}
                  exact
                />
                <Route
                  path="/allocate_staff"
                  element={<Subjectallocation />}
                  exact
                />
                <Route
                  path="/update_staff/:id"
                  element={<Staff_update />}
                  exact
                />
                <Route path="/staff_leave" element={<Staff_leave />} exact />

                {/* Timetable */}
                <Route
                  path="/generate_timetable"
                  element={
                    <TimetableProvider>
                      <Timetable_generate />
                    </TimetableProvider>
                  }
                  exact
                />
                <Route
                  path="/view_timetable"
                  element={<View_timetable />}
                  exact
                />
                <Route path="/substitution" element={<Substitution />} exact />
                <Route
                  path="/timetable_report_pdf/:user/:type/:data"
                  element={<Timetablereportpdf />}
                />
                {/* timetable reportz */}
                <Route path="/allclass_report" element={<Allclass />} />
                {/* <Route path="/allhistory_report" element={<Allhis />} /> */}
                <Route path="/allstaff_report" element={<Allstaff />} />
                <Route
                  path="/class_datespecific_report"
                  element={<Class_datespecific />}
                />
                <Route path="/classwise_report" element={<Classwise />} />
                {/* <Route
              path="/history_datespecific_report"
              element={<His />}
            /> */}
                <Route
                  path="/staff_datespecific_report"
                  element={<Staff_datespecific />}
                />
                <Route
                  path="/staff_classwise_report"
                  element={<Staff_classwise />}
                />
                <Route
                  path="/substitutionall_report"
                  element={<Substitutionall />}
                />
                <Route
                  path="/substitutionmonthly_report"
                  element={<Substitutionmonthly />}
                />
                {/*  */}
              </Routes>
            </AnimatePresence>
          </BrowserRouter>
        </Authdetailsprovider>
      </Suspense>
    </AuthProvider>
  );
}

export default App;
