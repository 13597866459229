import axios from "axios";
import Apiurl from "../Apiurl";
export default async function Apipost(url, formdata) {
  const apiurl = Apiurl();
  try {
    const response = await axios.post(apiurl + url, formdata);
    console.log(response.data);
    alert("Added Successfully");
    return response.data;
  } catch (error) {
    console.error(error);
    alert("Error Occured");
    return [{ error: "Error Posting data !" }];
  }
}
