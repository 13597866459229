import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Transition from "../../Helper/Transitionroute";
import { Input } from "antd";
import Select from "react-select";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import Apipost from "../../Helper/Apipost";
import Standardoptions from "../../Options/Standardoptions";
import Apifetch from "../../Helper/Apifetch";
import { AuthdetailsContext } from "../../Provider/Authdetailsprovider";

export default function Section_creation() {
  const { gid } = React.useContext(AuthdetailsContext);
  const standard_options = Standardoptions();
  const [data2, setData2] = useState([]);
  const [standard, setstandard] = useState(null);
  const [res, setres] = useState([]);
  const [groupoptions, setgroupoptions] = useState([]);
  const [description, setdescription] = useState("");
  const [group, setgroup] = useState([
    {
      section: "",
      max_students: "",
      group: "",
      description: "",
    },
  ]);
  const [open1, setopen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });
  const handleFormChange = (index, event) => {
    let data = [...group];
    data[index][event.target.name] = event.target.value;
    setgroup(data);
  };
  const removeFields = (index) => {
    let data = [...group];
    data.splice(index, 1);
    setgroup(data);
  };
  const addFields = () => {
    let newfield = { group: "", max_students: "", description: "" };

    setgroup([...group, newfield]);
  };
  async function postData(event) {
    event.preventDefault();

    try {
      const formdata = {
        standard_name: standard.value,
        description: description,
        section: group,
        erpidunique: gid,
      };
      Apipost("section/", formdata);
      setstandard("");
      setdescription("");
      setgroup([
        {
          section: "",
          max_students: "",
          group: "",
          description: "",
        },
      ]);
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  }
  async function fetchsectionData(val) {
    try {
      // const response = await axios.get(apiurl + `course/?course_name=${val}`);
      Apifetch(`course/?course_name=${val}`, setres).then((res) => {
        console.log(res.data);
        const convertedarray = res.data[0].group.map((item, index) => ({
          value: item.group,
          label: item.group,
        }));

        setgroupoptions(convertedarray);
      });
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">SECTION </div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Standard</div>
              <div class="grid-item">
                <Select
                  value={standard}
                  options={standard_options}
                  onChange={(value) => {
                    setstandard(value);
                    if ((value.value == "XII") | (value.value == "XI")) {
                      fetchsectionData(value.value);
                      console.log(value);
                    }
                  }}
                  placeholder="Select Standard"
                />
              </div>
              <div class="grid-item">Section</div>
              <div class="grid-item">
                <Input
                  type="text"
                  disabled
                  value={group.length}
                  className="textipbill"
                  style={{ width: "85%" }}
                />
                &nbsp;
                <Button
                  size="sm"
                  variant="warning"
                  onClick={() => {
                    setopen1(true);
                  }}
                >
                  +
                </Button>
              </div>
            </div>
            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={postData} size="sm">
                Submit
              </Button>
            </div>
          </div>
        </div>
        <Modal
          // title="Group"
          // centered
          open={open1}
          styles={{
            content: { backgroundColor: "white" }, // turns the Modal red
          }}
          onOk={() => setopen1(false)}
          onCancel={() => setopen1(false)}
          width={1200}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <Button size="sm" onClick={addFields}>
                Add
              </Button>{" "}
              &nbsp;
              {/* <CancelBtn /> */}
              <OkBtn size="sm" />
            </>
          )}
        >
          <h5>Section</h5>
          <hr></hr>
          <table>
            <tr style={{ padding: 2 }}>
              {/* <th> Sno</th> */}
              <th>Section</th>
              {standard &&
              (standard.value == "XI") | (standard.value == "XII") ? (
                <th>Group</th>
              ) : null}
              <th>Max Students</th>

              <th>Description</th>

              <th></th>
            </tr>

            {group.map((input, index) => {
              return (
                <>
                  <tr>
                    {/* <th>{index + 1}</th> */}
                    <th>
                      {" "}
                      <Input
                        name="section"
                        className="textipbill"
                        style={{
                          width: "150px",
                          border: "0.5px solid gray",
                          color: "black",
                        }}
                        placeholder="Section"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.name}
                      />
                    </th>
                    {standard &&
                    (standard.value == "XI") | (standard.value == "XII") ? (
                      <th>
                        {" "}
                        <select
                          name="group"
                          className="textipbill"
                          style={{
                            width: "150px",
                            border: "0.5px solid gray",
                            borderRadius: "7px",
                            height: "32px",
                          }}
                          onChange={(event) => handleFormChange(index, event)}
                          value={input.group}
                        >
                          <option value="">Select group</option>
                          {groupoptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </th>
                    ) : null}
                    <th>
                      {" "}
                      <Input
                        name="max_students"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="Maximum Students"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.max_students}
                      />{" "}
                    </th>

                    <th>
                      {" "}
                      <Input
                        name="description"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="Description"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.description}
                      />{" "}
                    </th>

                    <td>
                      {" "}
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => removeFields(index)}
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
          <br />
        </Modal>
      </div>
    </Maincomponent>
  );
}
