import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Transition from "../../Helper/Transitionroute";
import { Input } from "antd";
import axios from "axios";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import Apipost from "../../Helper/Apipost";
import Apifetch from "../../Helper/Apifetch";
import Apiput from "../../Helper/Apiput";

export default function Update_standard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [standard, setstandard] = useState("");
  const [modeldata, setmodaldata] = useState([]);
  const [description, setdescription] = useState("");
  const [group, setgroup] = useState([
    {
      group: "",
      registration_fee: "",
      admission_fee: "",
      tution_fee: "",
      book_fee: "",
      uniform_fee: "",
    },
  ]);
  const [open1, setopen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });
  const handleFormChange = (index, event) => {
    let data = [...group];
    data[index][event.target.name] = event.target.value;
    setgroup(data);
  };
  const removeFields = (index) => {
    let data = [...group];
    data.splice(index, 1);
    setgroup(data);
  };
  const addFields = () => {
    let newfield = {
      group: "",
      registration_fee: "0",
      admission_fee: "0",
      tution_fee: "0",
      book_fee: "0",
      uniform_fee: "0",
    };

    setgroup([...group, newfield]);
  };
  async function postData(event) {
    event.preventDefault();
    const formdata = {
      course_name: standard,
      description: description,
      group: group,
    };
    try {
      const res = await Apiput(`course/${id}/`, formdata);
    
      navigate("/view_standard");
    } catch (err) {
      alert("Error Occurred!");
      console.error(err);
    }
  }
  useEffect(() => {
    Apifetch(`course/${id}/`, setData).then((res) => {
      console.log(res.data);
      setstandard(res.data.course_name);
      setdescription(res.data.description);
      setgroup(res.data.group);
    });
  }, []);
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">UPDATE &nbsp;STANDARD </div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div className="grid-item">Standard</div>
              <div className="grid-item">
                <Input
                  type="text"
                  value={standard}
                  onChange={(e) => {
                    setstandard(e.target.value);
                    if (e.target.value != "XII" || e.target.value == "XI") {
                      setgroup([
                        {
                          group: "",
                          registration_fee: "",
                          admission_fee: "",
                          tution_fee: "",
                          book_fee: "",
                          uniform_fee: "",
                        },
                      ]);
                    }
                  }}
                  className="textipbill"
                />
              </div>
              <div className="grid-item">Group</div>
              <div className="grid-item">
                <Input
                  type="text"
                  disabled
                  value={group.length}
                  className="textipbill"
                  style={{ width: "85%" }}
                />
                &nbsp;
                <Button
                  size="sm"
                  variant="warning"
                  onClick={() => {
                    setopen1(true);
                  }}
                >
                  +
                </Button>
              </div>
              <div className="grid-item">Description</div>
              <div className="grid-item">
                <Input
                  type="text"
                  value={description}
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                  className="textipbill"
                />
              </div>
            </div>
            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={postData} size="sm">
                Update
              </Button>
            </div>
          </div>
        </div>
        <Modal
          // title="Group"
          // centered
          open={open1}
          styles={{
            content: { backgroundColor: "white" }, // turns the Modal red
          }}
          onOk={() => setopen1(false)}
          onCancel={() => setopen1(false)}
          width={1200}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              {standard == "XI" || standard == "XII" ? (
                <Button size="sm" onClick={addFields}>
                  Add
                </Button>
              ) : null}
              &nbsp;
              {/* <CancelBtn /> */}
              <OkBtn size="sm" />
            </>
          )}
        >
          <h5>Group</h5>
          <hr></hr>
          <table style={{ width: "100%" }}>
            <tr style={{ padding: 2 }}>
              {/* <th> Sno</th> */}
              <th>Group</th>
              {/* <th>Registration Fee</th>
              <th>Admission Fee</th>
              <th>Tution Fee</th>
              <th>Book Fee</th>
              <th>Uniform Fee</th> */}

              <th></th>
            </tr>

            {group.map((input, index) => {
              return (
                <>
                  <tr>
                    {/* <th>{index + 1}</th> */}
                    <th>
                      {" "}
                      <Input
                        name="group"
                        className="textipbill"
                        style={{
                          width: "80%",
                          border: "0.5px solid gray",
                          color: "black",
                        }}
                        disabled={
                          standard != "XI" && standard != "XII" ? true : false
                        }
                        placeholder="Group"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.group}
                      />
                    </th>
                    {/* <th>
                      {" "}
                      <Input
                        name="registration_fee"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="Fee"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.fee}
                      />{" "}
                    </th>
                    <th>
                      {" "}
                      <Input
                        name="admission_fee"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="Fee"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.fee}
                      />
                    </th>
                    <th>
                      {" "}
                      <Input
                        name="tution_fee"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="Fee"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.fee}
                      />
                    </th>
                    <th>
                      {" "}
                      <Input
                        name="book_fee"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="Fee"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.fee}
                      />
                    </th>
                    <th>
                      {" "}
                      <Input
                        name="uniform_fee"
                        className="textipbill"
                        style={{ width: "150px", border: "0.5px solid gray" }}
                        placeholder="Fee"
                        onChange={(event) => handleFormChange(index, event)}
                        value={input.fee}
                      />
                    </th> */}
                    <td>
                      {" "}
                      {standard == "XI" || standard == "XII" ? (
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() => removeFields(index)}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
          <br />
        </Modal>
      </div>
    </Maincomponent>
  );
}
