import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";

import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Apifetch from "../../Helper/Apifetch";
import Apidelete from "../../Helper/Apidelete";
import Standardoptions from "../../Options/Standardoptions";
import Sidebutton from "../../Components/Sidebutton/Sidebutton";
import Exporttoexcel from "../../Helper/Exporttoexcel";
import Staffoptions from "../../Options/Staffoptions";

export default function Staff_view() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const standard_option = Standardoptions();
  const [dataresponse, setdataresponse] = useState([]);

  const [modeldata, setmodaldata] = useState([]);
  const [name, setname] = useState("");
  const [role, setrole] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [password, setpassword] = useState("");
  const [status, setstatus] = useState("");
  const [staffid, setstaffid] = useState("");
  const [designation, setdesignation] = useState("");

  const [monday, setmonday] = useState([]);
  const [tuesday, settuesday] = useState([]);
  const [wednesday, setwednesday] = useState([]);
  const [thursday, setthursday] = useState([]);
  const [friday, setfriday] = useState([]);

  const [standard, setstandard] = useState(null);
  const staffoptions = Staffoptions();

  useEffect(() => {
    Apifetch("staffdetails/", setdataresponse);
  }, []);

  const filterData = () => {
    Apifetch(
      `staffdetails/?Role=&Status=&Staffid=${
        standard == null ? "" : standard.value
      }`,
      setdataresponse
    );
    setstandard(null);
  };
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);
      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>

              <td scope="col">{item.Staffid}</td>
              <td scope="col">{item.Name}</td>
              <td scope="col">{item.Role}</td>
              <td scope="col">{item.Designation}</td>
              <td>
                <Button
                  onClick={() => {
                    setname(item.Name);
                    setrole(item.Role);
                    setcontact(item.Contact_number);
                    setemail(item.Email);
                    setstaffid(item.Staffid);
                    setdesignation(item.Designation);
                    setmonday(item.monday);
                    settuesday(item.tuesday);
                    setwednesday(item.wednesday);
                    setthursday(item.thursday);
                    setfriday(item.friday);
                    setShow(true);
                  }}
                  type="button"
                  size="sm"
                  variant="outline-success"
                >
                  <i className="fa fa-eye"></i>
                </Button>
                &nbsp;
                <Button
                  onClick={() => {
                    navigate(`/update_staff/${item.id}`);
                  }}
                  type="button"
                  size="sm"
                  variant="outline-warning"
                >
                  <i className="fa fa-pencil"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      return (
        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Staff Id</th>
              <th>Name</th>
              <th>Role </th>
              <th>Designation</th>
              <th>Actions</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };

  return (
    <Maincomponent>
      <div className="rep-child-main">
        <Modal
          show={show}
          size="xl"
          onHide={() => setShow(false)}
          centered={false}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Staff
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="staffview_main">
              <div className="staffv_container1">
                <br />
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>{name}</th>
                    </tr>
                    <tr>
                      <th>Id</th>
                      <th>{staffid}</th>
                    </tr>
                    <tr>
                      <th>Designation</th>
                      <th>{designation}</th>
                    </tr>
                    <tr>
                      <th>Contact</th>
                      <th>{contact}</th>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <th>{email}</th>
                    </tr>
                  </thead>
                </Table>
              </div>
              <div className="staffv_container2">
                <br />
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Monday</td>

                      {monday.map((item, index) => (
                        <td>{item}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Tuesday</td>
                      {tuesday.map((item, index) => (
                        <td>{item}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Wednesday</td>
                      {wednesday.map((item, index) => (
                        <td>{item}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Thursday</td>
                      {thursday.map((item, index) => (
                        <td>{item}</td>
                      ))}
                    </tr>
                    <tr>
                      <td>Friday</td>
                      {friday.map((item, index) => (
                        <td>{item}</td>
                      ))}
                    </tr>
                  </tbody>
                </Table>{" "}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="rep-side-filter">
          <div className="rep-side-container">
            <div className="child-head">STAFF</div>
            <br />

            <div className="filter-ip-container">
              {/* Standard */}
              {/* <Input placeholder="Standard" /> */}
              <Select
                options={staffoptions}
                value={standard}
                onChange={(value) => {
                  setstandard(value);
                }}
              />
            </div>
            {/* <br /> */}
            <div className="filter-ip-container">
              <Sidebutton props="find" onClick={filterData} />
              <Sidebutton
                props="excel"
                onClick={() => {
                  Exporttoexcel(dataresponse);
                }}
              />
            </div>
          </div>
        </div>
        <div className="rep-side-table">
          {/* <br /> */}
          <div className="rep--table-container">
            <br />
            {dataresponse && <Tablecomp data={dataresponse} />}
          </div>
        </div>
      </div>
    </Maincomponent>
  );
}
