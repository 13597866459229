import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Transition from "../../Helper/Transitionroute";
import { Input } from "antd";
import Select from "react-select";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import Apipost from "../../Helper/Apipost";
import Standardoptions from "../../Options/Standardoptions";
import Apifetch from "../../Helper/Apifetch";
import Staffoptions from "../../Options/Staffoptions";
import axios from "axios";
import Apiurl from "../../Apiurl";
import { AuthdetailsContext } from "../../Provider/Authdetailsprovider";

export default function Subjectallocation() {
  const apiurl = Apiurl();
  const standardoptions = Standardoptions();
  const staffoption = Staffoptions();
  const [data, setdata] = useState([]);
  const [open1, setopen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [modaldata, setmodaldata] = useState([]);
  const { gid } = React.useContext(AuthdetailsContext);

  const [sub, setsub] = useState([]);
  const removeFields = (index) => {
    let data = [...sub];
    data.splice(index, 1);
    setsub(data);
  };
  const [staff, setstaff] = useState("");

  const [standard, setstandard] = useState("");
  const [subject, setsubject] = useState("");
  const [section, setsection] = useState("");
  const [description, setdescription] = useState("");

  const [subjectoptions, setsubjectoptions] = useState([]);

  const [sectionoptions, setsectionoptions] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  async function fetchfeedata(val) {
    try {
      console.warn(val);
      const response = await axios.get(
        apiurl + `subject/?standard=${val.value}`
      );
      const responseb = await axios.get(
        apiurl + `section/?standard_name=${val.value}`
      );

      const convertedarray = response.data[0].subject.map((item, index) => ({
        value: item.subject_name + "-" + item.hours,
        label: item.subject_name + "-" + item.hours,
      }));

      const convertedarray2 = responseb.data[0].section.map((item, index) => ({
        value: item.section,
        label: item.section,
      }));
      setsectionoptions(convertedarray2);
      setsubjectoptions(convertedarray);
    } catch (error) {
      console.error(error);
    }
  }
  async function postData(event) {
    try {
      const response = await axios.post(apiurl + "bulksubjectallocation/", sub);
      setstaff("");
      setsub([]);
      getData();
      console.log(sub);
      alert("Added Sucessfully");
    } catch (error) {
      console.error(error);
      alert("error");
      // Handle error here
    }
  }

  async function getData(event) {
    try {
      const response = await axios.get(
        apiurl + "staffdetails/?Role=Teaching&Status=&Staffid="
      );
      setdata(response.data);
    } catch (error) {
      console.error(error);
      alert("error");
      // Handle error here
    }
  }
  async function deleteData(id, staffid) {
    if (window.confirm("Do you really want to Delete the Data ?")) {
      try {
        const response = await axios.delete(
          apiurl + `staff_subject_allocation/${id}/`
        );
        setOpen2(false);
        alert("Deleted Sucessfully !");
        getstaffdata(staffid);
      } catch (error) {
        console.error(error);
        alert("error");
        // Handle error here
      }
    }
  }
  async function getstaffdata(Staffid) {
    try {
      const response = await axios.get(
        apiurl +
          `staff_subject_allocation/?staff=${Staffid}&standard=&section=&subject_name=`
      );

      setmodaldata(response.data);
      setOpen2(!open2);
    } catch (error) {
      console.error(error);
      alert("error");
    }
  }
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(4);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);
      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>

              <td>{item.Staffid}</td>
              <td>{item.Name}</td>
              <td>{item.Designation}</td>

              <td>
                {" "}
                <Button
                  size="sm"
                  variant="outline-info"
                  onClick={() => {
                    getstaffdata(item.Staffid);
                  }}
                >
                  View
                </Button>
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.slice(
        Math.max(0, currentPage - 2),
        Math.min(currentPage + 1, pageNumbers.length)
      );

      return (
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
            {renderPageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() =>
                  setCurrentPage(Math.ceil(totalPosts / postsPerPage))
                }
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Id</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Handling Classes</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  const Tablecomp2 = (props) => {
    const [data, setData] = useState(props.data);

    const [postsPerPage, setPostsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      try {
        if (data.length > 0) {
          return data.map((item, index) => (
            <tbody key={index}>
              <tr>
                <td>{postsPerPage * (currentPage - 1) + index + 1}</td>
                <td>{item.standard}</td>
                <td>{item.subject_name}</td>
                <td>{item.section}</td>
                <td>{item.description}</td>
                <td>
                  <Button
                    onClick={() => {
                      deleteData(item.id, item.staff);
                    }}
                    size="sm"
                    variant="outline-danger"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </td>
              </tr>
            </tbody>
          ));
        } else {
          return (
            <>
              <center>
                <h4>Subjects Not Allocated !</h4>
              </center>
            </>
          );
        }
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.slice(
        Math.max(0, currentPage - 2),
        Math.min(currentPage + 1, pageNumbers.length)
      );

      return (
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
            {renderPageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() =>
                  setCurrentPage(Math.ceil(totalPosts / postsPerPage))
                }
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>
      );
    };

    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          {props.data.length > 0 ? (
            <thead>
              <tr>
                {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
                <th>Sno</th>
                <th>Standard</th>
                <th>Subject</th>
                <th>Section</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
          ) : null}
          {showData()}
        </table>
      </div>
    );
  };
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">SUBJECT ALLOCATION</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Name</div>
              <div class="grid-item">
                <Select
                  placeholder="Staff"
                  value={staff == "" ? "Select Staff" : staff}
                  style={{ width: "100%" }}
                  onChange={(selectedOption) => {
                    setstaff(selectedOption);
                  }}
                  options={staffoption}
                />
              </div>
              <div class="grid-item"> Handling Classes</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={sub.length}
                  className="textipbill"
                  style={{ width: "70%" }}
                />
                &nbsp;
                <Button
                  size="sm"
                  variant="warning"
                  onClick={() => {
                    if (staff == "") {
                      alert("Selec Staff !");
                    } else {
                      setopen1(true);
                    }
                  }}
                >
                  +
                </Button>
              </div>
              {/*  */}
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={postData} size="sm">
                Submit
              </Button>
            </div>
          </div>
        </div>
        <br />
        <Tablecomp data={data} />
        <Modal
          open={open1}
          styles={{
            content: { backgroundColor: "white" }, // turns the Modal red
          }}
          onOk={() => setopen1(false)}
          onCancel={() => setopen1(false)}
          width={1200}
        >
          <h5>SUBJECT</h5>
          <hr></hr>
          <table>
            <tr style={{ padding: 2, width: "100%" }}>
              <td>
                {" "}
                <Select
                  value={standard == "" ? "Select Standard" : standard}
                  placeholder="Select Standard"
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setstandard(selectedOption);
                    fetchfeedata(selectedOption);
                  }}
                  options={standardoptions}
                />
              </td>
              <td>
                {" "}
                <Select
                  value={subject == "" ? "Select Subject" : subject}
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setsubject(selectedOption);
                  }}
                  options={subjectoptions}
                  placeholder="Select Subject"
                />
              </td>
              <td>
                <Select
                  value={section == "" ? "Select Section" : section}
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setsection(selectedOption);
                  }}
                  options={sectionoptions}
                  placeholder="Select Section"
                />
              </td>
              <td>
                {" "}
                <Input
                  className="textipbill"
                  style={{ width: "100%", border: "0.5px solid gray" }}
                  placeholder="Description"
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                  value={description}
                />
              </td>
              <th>
                {" "}
                <Button
                  onClick={async () => {
                    try {
                      const response = await axios.get(
                        apiurl +
                          `staff_subject_allocation/?staff=&standard=${standard.value}&section=${section.value}&subject_name=${subject.value}`
                      );

                      const fdata = sub.filter(
                        (person) =>
                          person.subject_name === subject &&
                          person.standard === standard &&
                          person.section === section
                      );
                      console.log(fdata.length);
                      console.log(subject);
                      const splitarray = subject.value.split("-");
                      console.log(splitarray);

                      if (response.data.length == 0) {
                        if (fdata.length == 0) {
                          const daa = {
                            staff: staff.value,
                            standard: standard.value,
                            subject_name: splitarray[0],
                            section: section.value,
                            description: description,
                            hour: splitarray[1],
                            erpidunique: gid,
                            
                          };
                          setsub([...sub, daa]);
                          setstandard("");
                          setsubject("");
                          setsection("");
                          setdescription("");
                          setsectionoptions([]);
                          setsubjectoptions([]);
                          console.log(sub);
                        } else {
                          alert("Already Added !");
                        }
                      } else {
                        alert("Already Staff Allocated !");
                      }
                    } catch (error) {
                      console.log(error);
                      alert(error);
                    }
                  }}
                >
                  ADD
                </Button>
              </th>
            </tr>
          </table>
          <br />
          <table style={{ width: "100%" }}>
            <tr style={{ padding: 5 }}>
              <th> Sno</th>
              <th>Standard</th>
              <th>Subject</th>
              <th>Section</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>

            {sub.map((item, index) => (
              <tr style={{ padding: 5 }}>
                <th> {index + 1}.</th>
                <td>{item.standard}</td>
                <td>{item.subject_name}</td>
                <td>{item.section}</td>
                <td>{item.description}</td>
                <td>
                  {" "}
                  <Button
                    onClick={() => {
                      removeFields(index);
                    }}
                    size="sm"
                    variant="outline-danger"
                  >
                    {" "}
                    X
                  </Button>{" "}
                </td>
              </tr>
            ))}
          </table>
          <br />
        </Modal>
        {/*  */}

        <Modal
          title="Subject Allocation"
          // centered
          open={open2}
          onOk={() => setOpen2(false)}
          width={1000}
        >
          <Tablecomp2 data={modaldata} />
        </Modal>
      </div>
    </Maincomponent>
  );
}
