import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";

import { Button } from "react-bootstrap";

import { Modal } from "antd";
import Apifetch from "../../Helper/Apifetch";
import Apidelete from "../../Helper/Apidelete";
import Standardoptions from "../../Options/Standardoptions";
import Sidebutton from "../../Components/Sidebutton/Sidebutton";
import Exporttoexcel from "../../Helper/Exporttoexcel";

export default function Subject_view() {
  const navigate = useNavigate();
  const standard_option = Standardoptions();
  const [dataresponse, setdataresponse] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [modeldata, setmodaldata] = useState([]);

  const [standard, setstandard] = useState(null);

  useEffect(() => {
    Apifetch("subject/", setdataresponse);
  }, []);

  const filterData = () => {
    Apifetch(
      `subject/?standard=${standard == null ? "" : standard.value}`,
      setdataresponse
    );
    setstandard(null);
  };
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);
      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>

              <td>{item.standard}</td>
              <td>
                {(item.group == null) | (item.group == "")
                  ? "Not Applicable"
                  : item.group}
              </td>
              <td>
                {" "}
                <Button
                  size="sm"
                  variant="outline-info"
                  onClick={() => {
                    setOpen2(!open2);
                    setmodaldata(item.subject);
                  }}
                >
                  View
                </Button>
              </td>

              <td>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    navigate(`/update_subject/${item.id}`);
                  }}
                >
                  <i className="fa fa-edit"></i>
                </Button>{" "}
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => {
                    Apidelete(`subject/`, item.id, setdataresponse);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </Button>{" "}
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      return (
        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Standard</th>
              <th>Group</th>
              <th> Subject</th>
              <th> Actions</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  const Tablecomp2 = (props) => {
    const [data, setData] = useState(props.data);

    const [postsPerPage, setPostsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      try {
        return data.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>
              <td>{item.subject_name}</td>
              <td>{item.hours}</td>
              <td>{item.description}</td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.slice(
        Math.max(0, currentPage - 2),
        Math.min(currentPage + 1, pageNumbers.length)
      );

      return (
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
            {renderPageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() =>
                  setCurrentPage(Math.ceil(totalPosts / postsPerPage))
                }
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>
      );
    };

    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Subject</th>
              <th>Hours</th>
              <th>Description</th>
            </tr>
          </thead>
          {showData()}
        </table>
      </div>
    );
  };
  return (
    <Maincomponent>
      <div className="rep-child-main">
        <div className="rep-side-filter">
          <div className="rep-side-container">
            <div className="child-head">SUBJECT</div>
            <br />

            <div className="filter-ip-container">
              {/* Standard */}
              {/* <Input placeholder="Standard" /> */}
              <Select
                options={standard_option}
                value={standard}
                onChange={(value) => {
                  setstandard(value);
                }}
              />
            </div>
            {/* <br /> */}
            <div className="filter-ip-container">
              <Sidebutton props="find" onClick={filterData} />
              <Sidebutton
                props="excel"
                onClick={() => {
                  Exporttoexcel(dataresponse);
                }}
              />
            </div>
          </div>
        </div>
        <div className="rep-side-table">
          {/* <br /> */}
          <div className="rep--table-container">
            <br />
            {dataresponse && <Tablecomp data={dataresponse} />}
          </div>
        </div>
        <Modal
          title="Group Data"
          // centered
          open={open2}
          onOk={() => setOpen2(false)}
          width={1000}
        >
          <Tablecomp2 data={modeldata} />
        </Modal>
      </div>
    </Maincomponent>
  );
}
