// TimetableContext.js
import React, { createContext, useState } from "react";

const TimetableContext = createContext();

const TimetableProvider = ({ children }) => {
  const [timetable, settimetable] = useState({
    Monday: Array(8).fill("-"),
    Tuesday: Array(8).fill("-"),
    Wednesday: Array(8).fill("-"),
    Thursday: Array(8).fill("-"),
    Friday: Array(8).fill("-"),
    Saturday: Array(8).fill("-"),
  });

  const [teacheropt, setteacheropt] = useState([]);

  return (
    <TimetableContext.Provider
      value={{ timetable, settimetable, teacheropt, setteacheropt }}
    >
      {children}
    </TimetableContext.Provider>
  );
};

export { TimetableContext, TimetableProvider };
