import React from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import { TbSearch } from "react-icons/tb";

import { Button } from "react-bootstrap";
export default function Sidebutton({ props, onClick }) {
  return (
    <>
      {props == "find" ? (
        <>
          {" "}
          <Button onClick={onClick} size="sm">
            <TbSearch />
          </Button>{" "}
        </>
      ) : null}

      {props == "excel" ? (
        <>
          {" "}
          <Button variant="success" onClick={onClick} size="sm">
            <RiFileExcel2Line />
          </Button>{" "}
        </>
      ) : null}
    </>
  );
}
