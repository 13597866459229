import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../../Components/Maincomponent/Maincomponent";

import axios from "axios";
import Apiurl from "../../../Apiurl";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { Input, Tag } from "antd";
import Select from "react-select";
import Staffoptions from "../../../Options/Staffoptions";
import Standardoptions from "../../../Options/Standardoptions";

export default function Staff_datespecific() {
  const { user } = useParams();
  const staffoptions = Staffoptions();
  const apiurl = Apiurl();
  const [jsonList, setJsonList] = useState([]);
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const Courseoption = Standardoptions;
  const [showtable, setshowtable] = useState(false);

  const [staff, setstaff] = useState(null);

  const [day, setday] = useState("");

  const [date, setdate] = useState("");

  const [standard, setstandard] = useState(null);
  const [section, setsection] = useState(null);
  // const subday = ;
  const [subday, setSubday] = useState([
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
  ]);
  const daydata = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const fetchdata = async () => {
    try {
      // console.log(apiurl + "timetablesection");
      const response = await axios.get(
        apiurl + `staffdetails/?Role=Teaching&Status=&Staffid=${staff.value}`
      );
      console.log(date);
      const response2 = await axios.get(
        apiurl + `subustitution/?date=${date}&staffid=&s_staffid=${staff.value}`
      );
      console.log(response2.data);
      console.log(response.data);

      setdata2(response2.data);

      // const a = response2.data.map((item, index) => {
      //   let s = parseInt(item.index);
      //   subday[s - 1] = item.period;
      // });
      let newSubday = [...subday];

      response2.data.forEach((item) => {
        let s = parseInt(item.index);
        newSubday[s - 1] = item.period;
      });
      setSubday(newSubday);

      console.log(subday);
      setdata(response.data);
      setshowtable(true);
    } catch (error) {
      console.log(error);
      console.log(error.response.data.error);
      alert(error.response.data.error);
    }
  };

  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">STAFF DATE SPECIFIC</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Staff</div>
              <div class="grid-item">
                {" "}
                <Select
                  style={{ width: "100%" }}
                  value={staff === "" ? "Select Staff" : staff}
                  onChange={(selectedOption) => {
                    setstaff(selectedOption);

                    setshowtable(false);
                    setsection("");
                  }}
                  options={staffoptions}
                  placeholder="Select Staff"
                />
              </div>
              <div class="grid-item">Date</div>
              <div class="grid-item">
                {" "}
                <Input
                  type="date"
                  size="large"
                  value={date}
                  onChange={(e) => {
                    setdate(e.target.value);
                    const date = new Date(e.target.value);
                    const dayOfWeek = date.getDay();
                    setday(daydata[dayOfWeek]);
                    setshowtable(false);
                  }}
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={fetchdata} size="sm" variant="primary">
                Find
              </Button>
              &nbsp;&nbsp;
              {showtable && (
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    const data = {
                      staffid: staff.value,
                      date: date,
                      day: day,
                    };
                    const aJSONString = JSON.stringify(data);
                    let encodedValue = btoa(aJSONString);
                    window.open(
                      `/timetable_report_pdf/${user}/staff_date_specific/${encodedValue}`
                    );
                  }}
                >
                  Generate Pdf
                </Button>
              )}
            </div>
          </div>
        </div>
        <br />
        {showtable && (
          <>
            {data.length == 0 ? (
              <center>
                <h5>NO RECORDS FOUND !</h5>
              </center>
            ) : (
              <div className="showtablestyle" style={{ padding: "5px" }}>
                {data.map((item, index) => (
                  <>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <Tag color="blue">{staff.value}</Tag>
                          </th>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>{day.toUpperCase()}</th>
                          {item[day].map((subject, index) => (
                            <td key={index}>
                              {(subject == "-") & (subday[index] != "-") ? (
                                <Tag color="purple">{subday[index]}</Tag>
                              ) : (
                                <>{subject}</>
                              )}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </Maincomponent>
  );
}
