import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { DatePicker, Input } from "antd";
import Select from "react-select";
import { Button } from "react-bootstrap";
import Apiurl from "../../Apiurl";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Tag } from "antd";
import Staffoptionwithid from "../../Options/Staffoptionwithid";
import { AuthdetailsContext } from "../../Provider/Authdetailsprovider";

export default function Staff_leave() {
  const { RangePicker } = DatePicker;
  const Staffoption = Staffoptionwithid("Teaching");
  const apiurl = Apiurl();
  const navigate = useNavigate();
  const [roledata, setroledata] = useState([]);
  const [sid, setsid] = useState(null);
  const [date, setdate] = useState("");
  const [reason, setreason] = useState("");
  const { gid } = React.useContext(AuthdetailsContext);

  const [dateRange, setDateRange] = useState([]);
  function convertToDDMMYYYY(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return year + "-" + month + "-" + day;
  }
  const handleDateChange = (dates) => {
    setDateRange(dates);
    console.log(dates);
  };

  const handleSubmit = async () => {
    let sdate =
      dateRange && dateRange[0]
        ? convertToDDMMYYYY(dateRange[0].toString())
        : "";
    let edate =
      dateRange && dateRange[1]
        ? convertToDDMMYYYY(dateRange[1].toString())
        : "";
    console.log(sdate);
    console.log(edate);
    const getDaysArray = function (start, end) {
      const arr = [];
      for (
        const dt = new Date(start);
        dt <= new Date(end);
        dt.setDate(dt.getDate() + 1)
      ) {
        const datea = convertToDDMMYYYY(new Date(dt).toString());
        arr.push(datea);
      }
      return arr;
    };
    const daylist = getDaysArray(new Date(sdate), new Date(edate));

    const splitval = sid.value.split("-");

    const formdata = {
      staffid: splitval[1],
      staffname: splitval[0],
      reason: reason,
      isapproved: "Pending",
      leave_date: date,
    };
    const convertedformdata = daylist.map((item, index) => ({
      staffid: splitval[1],
      staffname: splitval[0],
      reason: reason,
      isapproved: "Pending",
      leave_date: item,
      erpidunique: gid,
    }));
    console.log(convertedformdata);

    try {
      const response = await axios.post(
        apiurl + "bulkstaffleavecreate/",
        convertedformdata
      );
      console.log(response);
      fetchData();
      setsid(null);
      setdate("");
      setreason("");
      setDateRange([]);

      alert("Created!");
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };

  const [data, setData] = useState([]);

  async function fetchData() {
    try {
      const response = await axios.get(apiurl + "staff_leave/");
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);
      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>

              {/* <th >{i + 1}</th> */}

              <td scope="col">{item.staffid}</td>
              <td scope="col">{item.staffname}</td>
              <td scope="col">{item.leave_date}</td>
              <td scope="col">{item.reason}</td>
              <td scope="col">
                {item.isapproved == "Pending" ? (
                  <Tag color="blue">{item.isapproved}</Tag>
                ) : null}
                {item.isapproved == "Approved" ? (
                  <Tag color="blue">{item.isapproved}</Tag>
                ) : null}
                {item.isapproved == "Rejected" ? (
                  <Tag color="blue">{item.isapproved}</Tag>
                ) : null}
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.slice(
        Math.max(0, currentPage - 2),
        Math.min(currentPage + 1, pageNumbers.length)
      );

      return (
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
            {renderPageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() =>
                  setCurrentPage(Math.ceil(totalPosts / postsPerPage))
                }
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>
      );
    };

    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Staff Id</th>
              <th>Name</th>
              <th>Date</th>
              <th>Reason</th>
              <th>Approved </th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">STAFF LEAVE</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">ID</div>
              <div class="grid-item">
                <Select
                  value={sid === "" ? "Select Staff Id" : sid}
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setsid(selectedOption);
                  }}
                  options={Staffoption}
                  placeholder="Select Staff Id"
                />
              </div>
              <div class="grid-item">Date</div>
              <div class="grid-item">
                <RangePicker onChange={handleDateChange} value={dateRange} />
              </div>
              <div class="grid-item">Reason</div>
              <div class="grid-item">
                <Input
                  type="text"
                  onChange={(e) => {
                    setreason(e.target.value);
                  }}
                  // disabled
                  value={reason}
                  className="textipbill"
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={handleSubmit} size="sm">
                Submit
              </Button>
            </div>
          </div>
        </div>
        <br />
        <Tablecomp data={data} />
      </div>
    </Maincomponent>
  );
}
