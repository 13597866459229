// TimetableContext.js
import React, { createContext, useState } from "react";

const AuthdetailsContext = createContext();

const Authdetailsprovider = ({ children }) => {
  const [gid, setgid] = useState("");
  const [user, setuser] = useState("");
  const [username, setusername] = useState("");
  const [sid, setsid] = useState("");

  return (
    <AuthdetailsContext.Provider
      value={{ gid, setgid, user, setuser, username, setusername, sid, setsid }}
    >
      {children}
    </AuthdetailsContext.Provider>
  );
};

export { AuthdetailsContext, Authdetailsprovider };
