import * as XLSX from "xlsx";

const extractData = (obj, parentKey = "") => {
  let result = {};
  for (const key in obj) {
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      result = { ...result, ...extractData(obj[key], `${parentKey}${key}_`) };
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item, index) => {
        result = {
          ...result,
          ...extractData(item, `${parentKey}${key}_${index}_`),
        };
      });
    } else {
      result[`${parentKey}${key}`] = obj[key];
    }
  }
  return result;
};

const Exporttoexcel = (data) => {
  if (!data) {
    console.error("Data is not provided");
    return;
  }

  const formattedData = data.map((item) => extractData(item));

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, "data.xlsx");
};

export default Exporttoexcel;
