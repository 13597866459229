import React, { useState, useEffect } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Select } from "antd";
import { Button } from "react-bootstrap";
import Apiurl from "../../Apiurl";
import axios from "axios";
import Standardoptions from "../../Options/Standardoptions";
import Table from "react-bootstrap/Table";

export default function View_timetable() {
  const apiurl = Apiurl();
  const [sub, setsub] = useState([
    {
      subject_name: "",
      description: "",
    },
  ]);

  const [name, setname] = useState("");
  const Courseoption = Standardoptions();

  const [designation, setdesignation] = useState("");
  const [standard, setstandard] = useState("");
  const [sectionoption, setsectionoption] = useState([]);
  const [section, setsection] = useState("");
  const [data, setdata] = useState([]);
  const [showtable, setshowtable] = useState(false);

  async function getsectionoption(standard) {
    try {
      const response = await axios.get(
        apiurl + `section/?standard_name=${standard}`
      );

      if (response.data.length == 0) {
        alert("No Section Found in this Standard !");
      } else {
        const convertedoptions = response.data[0].section.map(
          (student, index) => ({
            value: student.section,
            label: student.section,
          })
        );
        setsectionoption(convertedoptions);
      }
    } catch (error) {
      console.error(error);
      alert("error");
    }
  }

  async function getteacheropt() {
    try {
      const response = await axios.get(
        apiurl + `timetablesection/?standard=${standard}&section=${section}`
      );

      if (response.data.length == 0) {
        alert("Timetable Not Generated !");
      } else {
        console.log(response.data);
        setshowtable(true);
        setdata(response.data[0]);
      }
    } catch (error) {
      console.error(error);
      alert("error");
    }
  }
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">TIMETABLE VIEW</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Standard</div>
              <div class="grid-item">
                <Select
                  value={standard === "" ? "Select Standard" : standard}
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setstandard(selectedOption);
                    getsectionoption(selectedOption);
                    setshowtable(false);
                    setsection("");
                    setsectionoption([]);
                  }}
                  options={Courseoption}
                  placeholder="Select Standard"
                />
              </div>
              <div class="grid-item">Section</div>
              <div class="grid-item">
                <Select
                  value={section === "" ? "Select Section" : section}
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setsection(selectedOption);
                    setshowtable(false);
                  }}
                  options={sectionoption}
                  placeholder="Select Section"
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={getteacheropt} size="sm">
                Submit
              </Button>
            </div>
          </div>
        </div>
        <br />
        {showtable && (
          <div style={{ padding: "5px", backgroundColor: "white" }}>
            <Table
              // responsive
              bordered
              hover
              style={{
                height: "100%",
                border: "0.01px solid grey",
              }}
            >
              <tr className="tablestaff">
                <th className="tablestafftd"></th>
                <th className="tablestafftd">1</th>
                <th className="tablestafftd">2</th>
                <th className="tablestafftd">3</th>
                <th className="tablestafftd">4</th>
                <th className="tablestafftd">5</th>
                <th className="tablestafftd">6</th>
                <th className="tablestafftd">7</th>
                <th className="tablestafftd">8</th>
              </tr>
              <tr className="tablestaff">
                <th className="tablestafftd">Monday</th>
                {data.monday.map((subject, index) => (
                  <td key={index}>{subject}</td>
                ))}
              </tr>
              <tr className="tablestaff">
                <th className="tablestafftd">Tuesday</th>
                {data.tuesday.map((subject, index) => (
                  <td key={index}>{subject}</td>
                ))}
              </tr>
              <tr className="tablestaff">
                <th className="tablestafftd">Wednesday</th>
                {data.wednesday.map((subject, index) => (
                  <td key={index}>{subject}</td>
                ))}
              </tr>
              <tr className="tablestaff">
                <th className="tablestafftd">Thursday</th>
                {data.thursday.map((subject, index) => (
                  <td key={index}>{subject}</td>
                ))}
              </tr>
              <tr className="tablestaff">
                <th className="tablestafftd">Friday</th>
                {data.friday.map((subject, index) => (
                  <td key={index}>{subject}</td>
                ))}
              </tr>
              {/* <tr className="tablestaff">
                    <th className="tablestafftd">Saturday</th>
                    {data.saturday.map((subject, index) => (
                      <td key={index}>{subject}</td>
                    ))}
                  </tr> */}
            </Table>
          </div>
        )}
      </div>
    </Maincomponent>
  );
}
