import React, { useState, useContext } from "react";
import "./maincomp.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMenu } from "react-icons/io5";
import { FcBriefcase } from "react-icons/fc";
import { FcConferenceCall } from "react-icons/fc";
import { FcBusinessman } from "react-icons/fc";
import { FcDataSheet } from "react-icons/fc";
import { Dropdown, Tooltip } from "antd";
import { Fade } from "react-awesome-reveal";
import { FcReading } from "react-icons/fc";
import { CgProfile } from "react-icons/cg";
import { FcRules } from "react-icons/fc";
import { FcBarChart } from "react-icons/fc";
import Logo from "../../Images/roosterico.png";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { AuthdetailsContext } from "../../Provider/Authdetailsprovider";

import { useAuth } from "../../Auth";

export default function Maincomponent({ children }) {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const { gid, setgid, user } = useContext(AuthdetailsContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigateto = (props) => {
    auth.login("admin");
    navigate(props);
  };

  const profile = [
    {
      key: "1",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/");
          }}
        >
          Logout
        </a>
      ),
    },
  ];
  const items = [
    {
      key: "1",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/create_standard");
          }}
        >
          Create
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/view_standard");
          }}
        >
          View
        </a>
      ),
    },
  ];
  const section_item = [
    {
      key: "3",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/create_section");
          }}
        >
          Create
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/view_section");
          }}
        >
          View
        </a>
      ),
    },
  ];
  const subject_item = [
    {
      key: "3",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/create_subject");
          }}
        >
          Create
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/view_subject");
          }}
        >
          View
        </a>
      ),
    },
  ];
  const staff_item = [
    {
      key: "1",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/create_staff");
          }}
        >
          Create
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/view_staff");
          }}
        >
          View
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/staff_leave");
          }}
        >
          Leave
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/allocate_staff");
          }}
        >
          Allocate Subject
        </a>
      ),
    },
  ];
  const timetable_item = [
    {
      key: "3",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/generate_timetable");
          }}
        >
          Generate
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/view_timetable");
          }}
        >
          View
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/substitution");
          }}
        >
          Substitution
        </a>
      ),
    },
  ];
  const report_item = [
    {
      key: "1",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/allclass_report");
          }}
        >
          Class - All
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/classwise_report");
          }}
        >
          Class Wise
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/class_datespecific_report");
          }}
        >
          Class - Date Specific
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/allstaff_report");
          }}
        >
          Staff - All
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/staff_classwise_report");
          }}
        >
          Staff - Class Wise
        </a>
      ),
    },
    {
      key: "6",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/staff_datespecific_report");
          }}
        >
          Staff - Date Specific
        </a>
      ),
    },
    {
      key: "7",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/substitutionall_report");
          }}
        >
          Subustitution - All
        </a>
      ),
    },
    {
      key: "8",
      label: (
        <a
          className="side_anchor"
          onClick={() => {
            navigateto("/substitutionmonthly_report");
          }}
        >
          Subustitution - Monthly
        </a>
      ),
    },
  ];

  return (
    <div className="maincomponent">
      <div className="main-nav">
        <div className="main-nav-contentbar">
          <div className="nav-icon-container">
            {user == "Admin" ? <IoMenu onClick={handleShow} /> : null}
          </div>
          <div className="nav-title">
            {" "}
            <img src={Logo} width={35} /> SCHEDULER
          </div>
          <div className="nav-icons-container">
            <Dropdown
              menu={{
                items: profile,
              }}
            >
              <CgProfile />
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="main-container">
        <Fade className="main-container">{children}</Fade>
      </div>

      <div className="bottom-nav">
        <div className="bottom-nav-content">Copyrights @ CBC 2024</div>
      </div>
      <Offcanvas
        className="sidebarcanvas"
        style={{ width: "3.5%" }}
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header>
          {/* <Offcanvas.Title>Menu</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={`menu-container`}>
            <div
              className="menu-item"
              onClick={() => {
                navigateto("/dashboard");
              }}
            >
              <FcBarChart />
              <span style={{ fontSize: "10px" }}> Dashboard</span>
            </div>
            <Dropdown
              menu={{
                items,
              }}
              placement="rightCenter"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <div className="menu-item">
                <FcBusinessman />
                <span style={{ fontSize: "10px" }}> Standard</span>
              </div>
            </Dropdown>
            <Dropdown
              menu={{
                items: section_item,
              }}
              placement="rightCenter"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <div className="menu-item">
                <FcConferenceCall />
                <span style={{ fontSize: "10px" }}> Section</span>
              </div>
            </Dropdown>

            <Dropdown
              menu={{
                items: subject_item,
              }}
              placement="rightCenter"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <div className="menu-item">
                <FcReading />
                <span style={{ fontSize: "10px" }}> Subject</span>
              </div>
            </Dropdown>
            <Dropdown
              menu={{
                items: staff_item,
              }}
              placement="rightCenter"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <div className="menu-item">
                <FcBriefcase />
                <span style={{ fontSize: "10px" }}> Staff</span>
              </div>
            </Dropdown>
            <Dropdown
              menu={{
                items: timetable_item,
              }}
              placement="rightCenter"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <div className="menu-item">
                <FcDataSheet />
                <span style={{ fontSize: "10px" }}> Timetable</span>
              </div>
            </Dropdown>
            <Dropdown
              menu={{
                items: report_item,
              }}
              placement="rightCenter"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <div className="menu-item">
                <FcRules />
                <span style={{ fontSize: "10px" }}> Reports</span>
              </div>
            </Dropdown>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
