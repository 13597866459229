import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../../Components/Maincomponent/Maincomponent";
import axios from "axios";
import Apiurl from "../../../Apiurl";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { Tag } from "antd";

export default function Allstaff() {
  const { user } = useParams();
  const apiurl = Apiurl();

  const [data, setdata] = useState([]);

  const [showtable, setshowtable] = useState(false);
  const fetchdata = async () => {
    try {
      // console.log(apiurl + "timetablesection");
      const response = await axios.get(
        apiurl + "staffdetails/?Role=Teaching&Status=&Staffid="
      );
      console.log(response.data);
      setdata(response.data);
      setshowtable(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">ALL STAFF REPORT</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              {showtable && (
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    window.open(`/timetable_report_pdf/${user}/allstaff/@#$%^`);
                  }}
                >
                  Generate Pdf
                </Button>
              )}
            </div>
          </div>
        </div>
        <br />
        {showtable && (
          <div className="showtablestyle">
            {data.map((item, index) => (
              <>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <Tag color="blue">
                          {item.Staffid} - {item.Name}{" "}
                        </Tag>
                      </th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Monday</th>
                      {item.monday.map((subject, index) => (
                        <td key={index}>{subject}</td>
                      ))}
                    </tr>
                    <tr>
                      <th>Tuesday</th>
                      {item.tuesday.map((subject, index) => (
                        <td key={index}>{subject}</td>
                      ))}
                    </tr>
                    <tr>
                      <th>Wednesday</th>
                      {item.wednesday.map((subject, index) => (
                        <td key={index}>{subject}</td>
                      ))}
                    </tr>
                    <tr>
                      <th>Thursday</th>
                      {item.thursday.map((subject, index) => (
                        <td key={index}>{subject}</td>
                      ))}
                    </tr>
                    <tr>
                      <th>Friday</th>
                      {item.friday.map((subject, index) => (
                        <td key={index}>{subject}</td>
                      ))}
                    </tr>
                  </tbody>
                </Table>
              </>
            ))}
          </div>
        )}
      </div>
    </Maincomponent>
  );
}
