import axios from "axios";
import Apiurl from "../Apiurl";
export default async function Apidelete(url, id, setdata) {
  const apiurl = Apiurl();
  if (window.confirm("Do you need to delete this data?")) {
    try {
      const response = await axios.delete(apiurl + url + `${id}/`);

      alert("Deleted Sucessfully!.");
      const response2 = await axios.get(apiurl + url);
      setdata(response2.data);
    } catch (error) {
      alert(error);
      return [{ error: "Error Deleting data !" }];
    }
  }
}
