import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../../Components/Maincomponent/Maincomponent";

import axios from "axios";
import Apiurl from "../../../Apiurl";
import Table from "react-bootstrap/Table";
import { Button } from "react-bootstrap";
import { Tag } from "antd";
import Select from "react-select";
import Staffoptions from "../../../Options/Staffoptions";
import Standardoptions from "../../../Options/Standardoptions";

export default function Staff_classwise() {
  const { user } = useParams();
  const apiurl = Apiurl();
  const [jsonList, setJsonList] = useState([]);
  const [data, setdata] = useState([]);
  const Courseoption = Standardoptions();
  const [showtable, setshowtable] = useState(false);

  const staffoptions = Staffoptions();

  const [staff, setstaff] = useState(null);
  const [standard, setstandard] = useState(null);
  const [sectionoption, setsectionoption] = useState([]);

  const [stdsec, setstdsec] = useState("");

  const [section, setsection] = useState(null);

  const fetchdata = async () => {
    if (staff != null) {
      if (standard.value && !section.value) {
        alert("Please Select Section!");
      } else if (
        (standard.value && section.value) ||
        (!standard.value && !section.value)
      ) {
        try {
          // console.log(apiurl + "timetablesection");
          const response = await axios.get(
            apiurl +
              `staffdetails/?Role=Teaching&Status=&Staffid=${staff.value}`
          );
          console.log(response.data);
          setdata(response.data);
          setshowtable(true);

          console.log(standard.value + "-" + section.value);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      alert("Please Select Staff!");
    }
  };

  async function getsectionoption(standard) {
    try {
      const response = await axios.get(
        apiurl + `section/?standard_name=${standard.value}`
      );

      const convertedoptions = response.data[0].section.map(
        (student, index) => ({
          value: student.section,
          label: student.section,
        })
      );
      setsectionoption(convertedoptions);
    } catch (error) {
      console.error(error);
      // alert("error");
    }
  }
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">STAFF CLASSWISE</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Staff</div>
              <div class="grid-item">
                {" "}
                <Select
                  style={{ width: "100%" }}
                  value={staff === "" ? "Select Staff" : staff}
                  onChange={(selectedOption) => {
                    setstaff(selectedOption);

                    setshowtable(false);
                    setstandard("");
                    setsection("");
                    setstdsec("");
                    setsectionoption([]);
                  }}
                  options={staffoptions}
                  placeholder="Select Staff"
                />
              </div>
              <div class="grid-item">Standard</div>
              <div class="grid-item">
                {" "}
                <Select
                  style={{ width: "100%" }}
                  value={standard === "" ? "Select Standard" : standard}
                  onChange={(selectedOption) => {
                    setstandard(selectedOption);
                    getsectionoption(selectedOption);
                    setshowtable(false);
                    setsection("");
                    setstdsec("");
                  }}
                  options={Courseoption}
                  placeholder="Select Standard"
                />
              </div>
              <div class="grid-item">Section</div>
              <div class="grid-item">
                {" "}
                <Select
                  style={{ width: "100%" }}
                  value={section === "" ? "Select Section" : section}
                  onChange={(selectedOption) => {
                    setsection(selectedOption);
                    setshowtable(false);
                    setstdsec("");
                  }}
                  options={sectionoption}
                  placeholder="Select Section"
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={fetchdata} size="sm" variant="primary">
                Find
              </Button>
              &nbsp;&nbsp;
              {showtable && (
                <Button variant="outline-danger">Generate Pdf</Button>
              )}
            </div>
          </div>
        </div>
        <br />
        {showtable && (
          <>
            {data.length == 0 ? (
              <center>
                <h5>NO RECORDS FOUND !</h5>
              </center>
            ) : (
              <div className="showtablestyle" style={{ padding: "5px" }}>
                {data.map((item, index) => (
                  <>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>
                            <Tag color="blue">
                              {item.Staffid} - {item.Name}{" "}
                            </Tag>
                          </th>
                          <th>1</th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>5</th>
                          <th>6</th>
                          <th>7</th>
                          <th>8</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Monday</th>
                          {item.monday.map((subject, index) => (
                            <td key={index}>
                              {}
                              {standard.value + "-" + section.value == subject
                                ? subject
                                : standard.value + "-" + section.value ==
                                  "undefined-undefined"
                                ? subject
                                : "-"}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th>Tuesday</th>
                          {item.tuesday.map((subject, index) => (
                            <td key={index}>
                              {standard.value + "-" + section.value == subject
                                ? subject
                                : standard.value + "-" + section.value ==
                                  "undefined-undefined"
                                ? subject
                                : "-"}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th>Wednesday</th>
                          {item.wednesday.map((subject, index) => (
                            <td key={index}>
                              {standard.value + "-" + section.value == subject
                                ? subject
                                : standard.value + "-" + section.value ==
                                  "undefined-undefined"
                                ? subject
                                : "-"}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th>Thursday</th>
                          {item.thursday.map((subject, index) => (
                            <td key={index}>
                              {standard.value + "-" + section.value == subject
                                ? subject
                                : standard.value + "-" + section.value ==
                                  "undefined-undefined"
                                ? subject
                                : "-"}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th>Friday</th>
                          {item.friday.map((subject, index) => (
                            <td key={index}>
                              {standard.value + "-" + section.value == subject
                                ? subject
                                : standard.value + "-" + section.value ==
                                  "undefined-undefined"
                                ? subject
                                : "-"}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </Maincomponent>
  );
}
