import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Transition from "../../Helper/Transitionroute";
import { Input } from "antd";
import Select from "react-select";
import { Modal } from "antd";
import { Button } from "react-bootstrap";
import Apipost from "../../Helper/Apipost";
import Standardoptions from "../../Options/Standardoptions";
import Apifetch from "../../Helper/Apifetch";
import Apiput from "../../Helper/Apiput";

export default function Staff_update() {
  const { id } = useParams();
  const [res, setres] = useState([]);
  const [name, setname] = useState("");
  const [role, setrole] = useState("Teaching");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [password, setpassword] = useState("");
  const [status, setstatus] = useState("");
  const [staffid, setstaffid] = useState("");
  const [designation, setdesignation] = useState("");

  const [monday, setmonday] = useState([]);
  const [tuesday, settuesday] = useState([]);
  const [wednesday, setwednesday] = useState([]);
  const [thursday, setthursday] = useState([]);
  const [friday, setfriday] = useState([]);

  const navigate = useNavigate();

  async function postData(event) {
    event.preventDefault();

    try {
      const formdata = {
        Name: name,
        Role: role,
        Contact_number: contact,
        Email: email,
        Password: password,
        Status: status,
        Staffid: staffid,
        Designation: designation,
        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
      };
      Apiput(`staffdetails/${id}/`, formdata).then((res) => {
        navigate(`/view_staff`);
      });
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  }
  useEffect(() => {
    Apifetch(`staffdetails/${id}/`, setres).then((res) => {
      setname(res.data.Name);
      setrole(res.data.Role);
      setcontact(res.data.Contact_number);
      setemail(res.data.Email);
      setstaffid(res.data.Staffid);
      setdesignation(res.data.Designation);
      setmonday(res.data.monday);
      settuesday(res.data.tuesday);
      setwednesday(res.data.wednesday);
      setthursday(res.data.thursday);
      setfriday(res.data.friday);
    });
  }, []);
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">STAFF</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Name</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={name}
                  className="textipbill"
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
              </div>
              <div class="grid-item">Designation</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={designation}
                  className="textipbill"
                  onChange={(e) => {
                    setdesignation(e.target.value);
                  }}
                />
              </div>

              <div class="grid-item">Contact</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={contact}
                  className="textipbill"
                  onChange={(e) => {
                    setcontact(e.target.value);
                  }}
                />
              </div>
              <div class="grid-item">Email</div>
              <div class="grid-item">
                <Input
                  type="text"
                  value={email}
                  className="textipbill"
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                />
              </div>
              <div class="grid-item">Staff Id</div>
              <div class="grid-item">
                <Input
                  disabled
                  type="text"
                  value={staffid}
                  className="textipbill"
                  onChange={(e) => {
                    setstaffid(e.target.value);
                  }}
                />
              </div>
              <div class="grid-item">Role</div>
              <div class="grid-item">
                <Input
                  disabled
                  type="text"
                  value={role}
                  className="textipbill"
                  onChange={(e) => {
                    setrole(e.target.value);
                  }}
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={postData} size="sm">
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Maincomponent>
  );
}
