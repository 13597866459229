import React, { useState, useEffect, useContext } from "react";
import { Select } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";

export default function Dynamicselectsub({
  props,
  changefunction,
  dataoptions,
  day,
  subject,
  isdisable,
  data,
}) {
  const apiurl = Apiurl();
  const [opt, setopt] = useState([]);

  function getRandomNumber() {
    return Math.floor(Math.random() * opt.length);
  }

  async function fetchData() {
    try {
      const splitvalue = subject.split("-");
      const section = splitvalue[1];
      const standard = splitvalue[0];
      const response = await axios.get(
        apiurl +
          `staff_subject_allocation_wr/?staff_id=&section=${section}&standard=${standard}`
      );
      // console.log(response.data);
      const lowercaseDay = day.toLowerCase();
      const filteredData = response.data.filter(
        (student) =>
          student[lowercaseDay] && student[lowercaseDay][props] === "-"
      );
      const options3 = filteredData.map((student) => ({
        value: student.Staffid + "-" + student.subject,
        label: student.Staffid + "-" + student.subject,
      }));
      setopt(options3);
      if (options3.length > 0) {
        const randomIndex = Math.floor(Math.random() * options3.length);
        const defaultValue = options3[randomIndex].value;
      }
    } catch (error) {
      console.error(error);
      console.log("error");
    }
  }
  const a = getRandomNumber();
  const dftval = opt && opt[a]?.value;

  useEffect(() => {
    fetchData();
  }, [0]);
  return (
    <div>
      <Select
        style={{ width: "80%" }}
        onChange={changefunction}
        options={opt}
        placeholder={dftval}
        disabled={isdisable}
      />
    </div>
  );
}
