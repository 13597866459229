import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    borderWidth: 1,
    fontSize: 5,
    fontFamily: "Times-Roman",
    padding: 20,
  },
  viewer: {
    width: "100%", // Use percentage to ensure responsiveness
    height: "100vh", // Use viewport height for full height
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    marginBottom: 10,
    fontSize: 5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "#f0f0f0",
  },
  tableCol: {
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 9,
  },
});

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};
function Historypdf() {
  const apiurl = Apiurl();
  const { id } = useParams();
  const [stddata, setstddata] = useState([]);

  const fetchdata = async () => {
    try {
      // console.log(apiurl + "timetablesection");
      const response = await axios.get(apiurl + "timetablehistory/");
      console.log(response.data);
      setstddata(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  // Split data into chunks of 3 items each
  const chunks = chunkArray(stddata, 3);

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        {chunks.map((chunk, pageIndex) => (
          <Page key={pageIndex} size="A4" style={styles.page}>
            {chunk.map((classData, index) => (
              <View key={index} style={{ marginBottom: 20 }}>
                <Text
                  style={{ marginBottom: 10, fontSize: 14, fontWeight: "bold" }}
                >
                  {classData.date} Standard: {classData.standard} - Section:{" "}
                  {classData.section}
                </Text>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Day</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Period 1</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Period 2</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Period 3</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Period 4</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Period 5</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Period 6</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Period 7</Text>
                    </View>
                    <View style={styles.tableColHeader}>
                      <Text style={styles.tableCellHeader}>Period 8</Text>
                    </View>
                  </View>
                  {["monday", "tuesday", "wednesday", "thursday", "friday"].map(
                    (day, dayIndex) => (
                      <View key={dayIndex} style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </Text>
                        </View>
                        {classData[day].map((period, periodIndex) => (
                          <View key={periodIndex} style={styles.tableCol}>
                            <Text style={styles.tableCell}>{period}</Text>
                          </View>
                        ))}
                      </View>
                    )
                  )}
                </View>
              </View>
            ))}
          </Page>
        ))}
      </Document>
    </PDFViewer>
  );
}

export default Historypdf;
