import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Allclasspdf from "../../Components/Timetable_report_pdf/Allclasspdf";
import Classwisepdf from "../../Components/Timetable_report_pdf/Class_wisepdf";
import Class_wise_date_specific_pdf from "../../Components/Timetable_report_pdf/Class_dete_specific_pdf";
import Allstaffpdf from "../../Components/Timetable_report_pdf/Allstaffpdf";
import Staff_date_specific_pdf from "../../Components/Timetable_report_pdf/Stafff_date_specificpdf";
import Substitutionpdf from "../../Components/Timetable_report_pdf/Substitutionpdf";
import Substitution_monthly_pdf from "../../Components/Timetable_report_pdf/Substitution_monthlypdf";
import Historypdf from "../../Components/Timetable_report_pdf/Historypdf";

export default function Timetablereportpdf() {
  const { user, type } = useParams();

  return (
    <>
      {type == "allclass" ? <Allclasspdf /> : null}
      {type == "allstaff" ? <Allstaffpdf /> : null}
      {type == "class_date_specific" ? <Class_wise_date_specific_pdf /> : null}
      {type == "classwise" ? <Classwisepdf /> : null}
      {type == "staff_classwise" ? <Allclasspdf /> : null}
      {type == "staff_date_specific" ? <Staff_date_specific_pdf /> : null}
      {type == "substitution" ? <Substitutionpdf /> : null}
      {type == "substitution_monthly" ? <Substitution_monthly_pdf /> : null}
      {type == "history" ? <Historypdf /> : null}
    </>
  );
}
