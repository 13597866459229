import React, { useState, useEffect } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Select from "react-select";
import { Button } from "react-bootstrap";
import Apiurl from "../../Apiurl";
import axios from "axios";
import Standardoptions from "../../Options/Standardoptions";
import Table from "react-bootstrap/Table";
import Dynamicselectsub from "./Dynamicselectsub";
import { Input } from "antd";

export default function Substitution() {
  const apiurl = Apiurl();
  const [staffid, setstaffid] = useState(null);
  const [data, setdata] = useState([]);
  const [date, setdate] = useState("");
  const [day, setday] = useState("");
  const [staffOptions, setStaffOptions] = useState([]);

  const [converteddata, setconverteddata] = useState([]);
  const [showtable, setshowtable] = useState(false);

  const fetchStaffOptions = async (date) => {
    try {
      const response = await axios.get(
        apiurl + `staff_leave/?staffid=&isapproved=&leave_date=${date}`
      );
      console.log(response.data);
      const options = response.data.map((student) => ({
        value: student.staffid + "-" + student.staffname,
        label: student.staffid + "-" + student.staffname,
      }));
      setStaffOptions(options);
    } catch (error) {
      console.error(error);
      console.log("network error");
    }
  };
  const submitdata = async () => {
    try {
      const idsplit = staffid.value.split("-");

      const response1 = await axios.get(
        apiurl + `subustitution/?date=${date}&staffid=${idsplit[0]}&s_staffid=`
      );
      console.log(response1.data);
      if (response1.data.length > 0) {
        alert("Substitution Already Allocated!");
      } else {
        const response = await axios.get(
          apiurl + `staffdetails/?Role=&Status=&Staffid=${idsplit[0]}`
        );
        const response2 = await axios.get(
          apiurl +
            `staff_subject_allocation/?staff=${idsplit[0]}&standard=&section=&subject_name=`
        );
        console.log(response2.data);
        setdata(response.data[0]);
        setshowtable(true);

        const convertedarray = response.data[0][day].map((item, index) => ({
          staffid: idsplit[0],
          staffname: idsplit[1],
          period: item,
          index: index,
          s_staffid: "",
          s_subject: "",
          date: date,
        }));
        console.warn(convertedarray);
        setconverteddata(convertedarray);
      }
    } catch (error) {
      console.error(error);
      console.log("network error");
    }
  };
  const daydata = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const handleFormChange = (index, event) => {
    let data = [...converteddata];
    let splitval = event.split("-");
    converteddata[index]["s_staffid"] = splitval[0];
    converteddata[index]["s_subject"] = splitval[1];
    setconverteddata(data);
  };

  const submitdatas = async () => {
    const filterdata = converteddata.filter((item) => item.period != "-");
    const response = await axios.post(
      apiurl + `bulksubustitution/`,
      filterdata
    );
    console.log(response);
  };
  return (
    <Maincomponent>
      <div className="child-main">
        <div className="child-head">SUBSTITUTION</div>
        <br />
        <div className="childip-main">
          <div className="child-ip-container">
            <div className="childip">
              <div class="grid-item">Date</div>
              <div class="grid-item">
                <Input
                  className="loginipt"
                  type="date"
                  // suffix={suffix}
                  size="large"
                  onChange={(e) => {
                    setdate(e.target.value);
                    fetchStaffOptions(e.target.value);

                    const date = new Date(e.target.value);
                    const dayOfWeek = date.getDay();
                    setday(daydata[dayOfWeek]);
                  }}
                />
              </div>
              <div class="grid-item">Staff</div>
              <div class="grid-item">
                <Select
                  value={staffid === "" ? "Select Staff Id" : staffid}
                  style={{ width: "150px" }}
                  onChange={(selectedOption) => {
                    setstaffid(selectedOption);
                  }}
                  options={staffOptions}
                  placeholder="Select Staff Id"
                />
              </div>
            </div>

            <br />
            <div
              style={{
                width: "90%",
                display: "flex",
                justifyContent: "flex-end",
                // backgroundColor: "red",
              }}
            >
              <Button onClick={submitdata} size="sm">
                Find
              </Button>
            </div>
          </div>
        </div>
        <br />
        <>
          {showtable && (
            <div className="showtablestyle"  style={{ padding: "10px", backgroundColor: "white" }}>
              <Table
                // responsive
                bordered
                style={{
                  height: "100%",
                  border: "0.01px solid grey",
                  borderRadius: "5px",
                }}
              >
                <tr className="tablestaff">
                  <th className="tablestafftd"></th>
                  <th className="tablestafftd">1</th>
                  <th className="tablestafftd">2</th>
                  <th className="tablestafftd">3</th>
                  <th className="tablestafftd">4</th>
                  <th className="tablestafftd">5</th>
                  <th className="tablestafftd">6</th>
                  <th className="tablestafftd">7</th>
                  <th className="tablestafftd">8</th>
                </tr>
                <tr className="tablestaff">
                  <th className="tablestafftd" rowSpan={2}>
                    {day.toUpperCase()}
                  </th>

                  {converteddata.map((item, index) => (
                    <td key={index}>
                      <>{item.period}</>
                    </td>
                  ))}
                </tr>
                <tr className="tablestaff">
                  {/* {data[day].map((subject, index) => (
                      <td key={index}>
                        <>
                          <Dynamicselectsub
                            timetabledata={null}
                            subject={subject}
                            props={index}
                            day={day}
                            isdisable={subject == "-"}
                            changefunction={null}
                          />
                        </>
                      </td>
                    ))} */}

                  {converteddata.map((item, index) => (
                    <td key={index}>
                      <>
                        <Dynamicselectsub
                          data={converteddata}
                          subject={item.period}
                          props={index}
                          day={day}
                          isdisable={item.period == "-"}
                          changefunction={(event) => {
                            handleFormChange(index, event);
                          }}
                        />
                      </>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td colSpan={8}></td>
                  <td>
                    <div>
                      <Button size="sm" onClick={submitdatas}>
                        Submit
                      </Button>
                    </div>
                  </td>
                </tr>
              </Table>
            </div>
          )}
        </>
      </div>
    </Maincomponent>
  );
}
